// Eddtech Types
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

export const REQ_LOGIN_ = "REQ_LOGIN_";
export const LOGIN_SUCCESS_ = "LOGIN_SUCCESS_";
export const LOGIN_FAILED_ = "LOGIN_FAILED_";

export const GET_ACCESSTOKEN = "GET_ACCESSTOKEN";
export const GET_ACCESSTOKEN_SUCCESS = "GET_ACCESSTOKEN_SUCCESS";
export const GET_ACCESSTOKEN_FAILED = "GET_ACCESSTOKEN_FAILED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const GET_TEST_QUESTIONS = "GET_TEST_QUESTIONS";
export const GET_TEST_QUESTIONS_SUCCESS = "GET_TEST_QUESTIONS_SUCCESS";
export const GET_TEST_QUESTIONS_FAILED = "GET_TEST_QUESTIONS_FAILED";

export const GET_TEST_RESULT = "GET_TEST_RESULT";
export const GET_TEST_RESULT_SUCCESS = "GET_TEST_RESULT_SUCCESS";
export const GET_TEST_RESULT_FAILED = "GET_TEST_RESULT_FAILED";

export const GET_TEST_RESULT_ANS_RESPONSE = "GET_TEST_RESULT_ANS_RESPONSE";
export const GET_TEST_RESULT_ANS_RESPONSE_SUCCESS =
	"GET_TEST_RESULT_ANS_RESPONSE_SUCCESS";
export const GET_TEST_RESULT_ANS_RESPONSE_FAILED =
	"GET_TEST_RESULT_ANS_RESPONSE_FAILED";

export const GET_STUDENT = "GET_STUDENT";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FAILED = "GET_STUDENT_FAILED";

export const GET_INPROGRESS_TESTS = "GET_INPROGRESS_TESTS";
export const GET_INPROGRESS_TESTS_SUCCESS = "GET_INPROGRESS_TESTS_SUCCESS";
export const GET_INPROGRESS_TESTS_FAILED = "GET_INPROGRESS_TESTS_FAILED";

export const GET_COMPLETED_TESTS = "GET_COMPLETED_TESTS";
export const GET_COMPLETED_TESTS_SUCCESS = "GET_COMPLETED_TESTS_SUCCESS";
export const GET_COMPLETED_TESTS_FAILED = "GET_COMPLETED_TESTS_FAILED";

export const GET_NOT_STARTED_TESTS = "GET_NOT_STARTED_TESTS";
export const GET_NOT_STARTED_TESTS_SUCCESS = "GET_NOT_STARTED_TESTS_SUCCESS";
export const GET_NOT_STARTED_TESTS_FAILED = "GET_NOT_STARTED_TESTS_FAILED";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILED = "GET_QUESTIONS_FAILED";

export const GET_QUESTION_ANALYSIS = "GET_QUESTION_ANALYSIS";
export const GET_QUESTION_ANALYSIS_SUCCESS = "GET_QUESTION_ANALYSIS_SUCCESS";
export const GET_QUESTION_ANALYSIS_FAILED = "GET_QUESTION_ANALYSIS_FAILED";

export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAILED = "GET_SUBJECTS_FAILED";

export const SAVE_ANS_RESPONSE = "SAVE_ANS_RESPONSE";
export const SAVE_ANS_RESPONSE_SUCCESS = "SAVE_ANS_RESPONSE_SUCCESS";
export const SAVE_ANS_RESPONSE_FAILED = "SAVE_ANS_RESPONSE_FAILED";

export const SAVE_TEST_ANS_RESPONSE = "SAVE_TEST_ANS_RESPONSE";
export const SAVE_TEST_ANS_RESPONSE_SUCCESS = "SAVE_TEST_ANS_RESPONSE_SUCCESS";
export const SAVE_TEST_ANS_RESPONSE_FAILED = "SAVE_TEST_ANS_RESPONSE_FAILED";

export const SAVE_ANS = "SAVE_ANS";
export const SAVE_ANS_SUCCESS = "SAVE_ANS_SUCCESS";
export const SAVE_ANS_FAILED = "SAVE_ANS_FAILED";

export const SUBMIT_TEST = "SUBMIT_TEST";
export const SUBMIT_TEST_SUCCESS = "SUBMIT_TEST_SUCCESS";
export const SUBMIT_TEST_FAILED = "SUBMIT_TEST_FAILED";

export const UPDATE_TEST_STATUS = "UPDATE_TEST_STATUS";
export const UPDATE_TEST_STATUS_SUCCESS = "UPDATE_TEST_STATUS_SUCCESS";
export const UPDATE_TEST_STATUS_FAILED = "UPDATE_TEST_STATUS_FAILED";

export const ADD_STUDENT = "ADD_STUDENT";
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS";
export const ADD_STUDENT_FAILED = "ADD_STUDENT_FAILED";

export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAILED = "UPDATE_STUDENT_FAILED";

export const GET_TEST_LIST = "GET_TEST_LIST";
export const GET_TEST_LIST_SUCCESS = "GET_TEST_LIST_SUCCESS";
export const GET_TEST_LIST_FAILED = "GET_TEST_LIST_FAILED";

export const GET_TOTAL_TEST_COUNT = "GET_TOTAL_TEST_COUNT";
export const GET_TOTAL_TEST_COUNT_SUCCESS = "GET_TOTAL_TEST_COUNT_SUCCESS";
export const GET_TOTAL_TEST_COUNT_FAILED = "GET_TOTAL_TEST_COUNT_FAILED";

export const GET_CLASSROOM = "GET_CLASSROOM";
export const GET_CLASSROOM_SUCCESS = "GET_CLASSROOM_SUCCESS";
export const GET_CLASSROOM_FAILED = "GET_CLASSROOM_FAILED";

export const ADD_NEW_TEST = "ADD_NEW_TEST";
export const ADD_NEW_TEST_SUCCESS = "ADD_NEW_TEST_SUCCESS";
export const ADD_NEW_TEST_FAILED = "ADD_NEW_TEST_FAILED";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

export const QUESTION_META_DATA = "QUESTION_META_DATA";
export const QUESTION_META_DATA_SUCCESS = "QUESTION_META_DATA_SUCCESS";
export const QUESTION_META_DATA_FAILURE = "QUESTION_META_DATA_FAILURE";

export const SUBJECT_META_DATA = "SUBJECT_META_DATA";
export const SUBJECT_META_DATA_SUCCESS = "SUBJECT_META_DATA_SUCCESS";
export const SUBJECT_META_DATA_FAILURE = "SUBJECT_META_DATA_FAILURE";

export const CHAPTER_META_DATA = "CHAPTER_META_DATA";
export const CHAPTER_META_DATA_SUCCESS = "CHAPTER_META_DATA_SUCCESS";
export const CHAPTER_META_DATA_FAILURE = "CHAPTER_META_DATA_FAILURE";

export const TOPIC_META_DATA = "TOPIC_META_DATA";
export const TOPIC_META_DATA_SUCCESS = "TOPIC_META_DATA_SUCCESS";
export const TOPIC_META_DATA_FAILURE = "TOPIC_META_DATA_FAILURE";

export const GET_QUESTIONS_TO_SET = "GET_QUESTIONS_TO_SET";
export const GET_QUESTIONS_TO_SET_SUCCESS = "GET_QUESTIONS_TO_SET_SUCCESS";
export const GET_QUESTIONS_TO_SET_FAILURE = "GET_QUESTIONS_TO_SET_FAILURE";

export const ENROLL_STUDENT = "ENROLL_STUDENT";
export const ENROLL_STUDENT_SUCCESS = "ENROLL_STUDENT_SUCCESS";
export const ENROLL_STUDENT_FAILURE = "ENROLL_STUDENT_FAILURE";

export const GET_QUESTION_COUNT = "GET_QUESTION_COUNT";
export const GET_QUESTION_COUNT_SUCCESS = "GET_QUESTION_COUNT_SUCCESS";
export const GET_QUESTION_COUNT_FAILURE = "GET_QUESTION_COUNT_FAILURE";

export const SEND_SELECTED_QUESTION = "SEND_SELECTED_QUESTION";
export const SEND_SELECTED_QUESTION_SUCCESS = "SEND_SELECTED_QUESTION_SUCCESS";
export const SEND_SELECTED_QUESTION_FAILURE = "SEND_SELECTED_QUESTION_FAILURE";

export const TOTAL_TEST_COUNT_BY_USER = "TOTAL_TEST_COUNT_BY_USER";
export const TOTAL_TEST_COUNT_BY_USER_SUCCESS =
	"TOTAL_TEST_COUNT_BY_USER_SUCCESS";
export const TOTAL_TEST_COUNT_BY_USER_FAILURE =
	"TOTAL_TEST_COUNT_BY_USER_FAILURE";

export const ADD_CLASSROOM = "ADD_CLASSROOM";
export const ADD_CLASSROOM_SUCCESS = "ADD_CLASSROOM_SUCCESS";
export const ADD_CLASSROOM_FAILURE = "ADD_CLASSROOM_FAILURE";

export const ADD_TEACHER = "ADD_TEACHER";
export const ADD_TEACHER_SUCCESS = "ADD_TEACHER_SUCCESS";
export const ADD_TEACHER_FAILURE = "ADD_TEACHER_FAILURE";

export const ERASE_TEACHER_ADD_DATA = "ERASE_TEACHER_ADD_DATA";

export const GET_QUESTION_PAPER = "GET_QUESTON_PAPER";
export const GET_QUESTION_PAPER_SUCCESS = "GET_QUESTON_PAPER_SUCCESS";
export const GET_QUESTION_PAPER_FAILURE = "GET_QUESTON_PAPER_FAILURE";

export const EMPTY_ERROR = "EMPTY_ERROR";

export const REPORT_QUESTION = "REPORT_QUESTION";
export const REPORT_QUESTION_SUCCESS = "REPORT_QUESTION_SUCCESS";
export const REPORT_QUESTION_FAILURE = "REPORT_QUESTION_FAILURE";

export const CHANGE_STUDENT_PASSWORD = "CHANGE_STUDENT_PASSWORD";
export const CHANGE_STUDENT_PASSWORD_SUCCESS =
	"CHANGE_STUDENT_PASSWORD_SUCCESS";
export const CHANGE_STUDENT_PASSWORD_FAILURE =
	"CHANGE_STUDENT_PASSWORD_FAILURE";

export const ERASE_CHANGE_PASSWORD_MESSAGE = "ERASE_CHANGE_PASSWORD_MESSAGE";

export const CHANGE_STANDARD_ID = "CHANGE_STANDARD_ID";
export const CHANGE_SUBJECT_ID = "CHANGE_SUBJECT_ID";
export const CHANGE_CHAPTER_ID = "CHANGE_CHAPTER_ID";
export const CHANGE_TOPIC_ID = "CHANGE_TOPIC_ID";
export const CHANGE_DIFFICULTY_LEVEL_ID = "CHANGE_DIFFICULTY_LEVEL_ID";
export const CHANGE_QUESTION_TYPE_ID = "CHANGE_QUESTION_TYPE_ID";

export const RESET_FILTER = "RESET_FILTER";

export const CHANGE_CORRECT_ANSWER_MARKS = "CHANGE_CORRECT_ANSWER_MARKS";
export const CHANGE_WRONG_ANSWER_MARKS = "CHANGE_WRONG_ANSWER_MARKS";

export const ADD_SELECTED_QUESTION_DATA = "ADD_SELECTED_QUESTION_DATA";
export const EMPTY_SELECTED_QUESTION_DATA = "EMPTY_SELECTED_QUESTION_DATA";

export const EMPTY_PAST_FRONTEND_STATE = "EMPTY_PAST_FRONTEND_STATE";

export const RENAME_CLASSROOM = "RENAME_CLASSROOM";
export const RENAME_CLASSROOM_SUCCESS = "RENAME_CLASSROOM_SUCCESS";
export const RENAME_CLASSROOM_FAILURE = "RENAME_CLASSROOM_FAILURE";

export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const DELETE_CLASSROOM_SUCCESS = "DELETE_CLASSROOM_SUCCESS";
export const DELETE_CLASSROOM_FAILURE = "DELETE_CLASSROOM_FAILURE";

export const ERASE_CLASSROOM_MESSAGE = "ERASE_CLASSROOM_MESSAGE";

export const EDIT_STUDENT = "EDIT_STUDENT";
export const EDIT_STUDENT_SUCCESS = "EDIT_STUDENT_SUCCESS";
export const EDIT_STUDENT_FAILURE = "EDIT_STUDENT_FAILURE";

export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";

export const STUDENT_DETAILS_CLASSROOM = "STUDENT_DETAILS_CLASSROOM";
export const STUDENT_DETAILS_CLASSROOM_SUCCESS =
	"STUDENT_DETAILS_CLASSROOM_SUCCESS";
export const STUDENT_DETAILS_CLASSROOM_FAILURE =
	"STUDENT_DETAILS_CLASSROOM_FAILURE";

export const GET_CLASSROOM_STUDENTS = "GET_CLASSROOM_STUDENTS";
export const GET_CLASSROOM_STUDENTS_SUCCESS = "GET_CLASSROOM_STUDENTS_SUCCESS";
export const GET_CLASSROOM_STUDENTS_FAILURE = "GET_CLASSROOM_STUDENTS_FAILURE";

export const GET_CLASSROOM_STUDENTS_COUNT = "GET_CLASSROOM_STUDENTS_COUNT";
export const GET_CLASSROOM_STUDENTS_SUCCESS_COUNT =
	"GET_CLASSROOM_STUDENTS_SUCCESS_COUNT";
export const GET_CLASSROOM_STUDENTS_FAILURE_COUNT =
	"GET_CLASSROOM_STUDENTS_FAILURE_COUNT";

export const EDIT_CLASSROOM_STUDENTS_DETAILS =
	"EDIT_CLASSROOM_STUDENTS_DETAILS";
export const EDIT_CLASSROOM_STUDENTS_DETAILS_SUCCESS =
	"EDIT_CLASSROOM_STUDENTS_DETAILS_SUCCESS";
export const EDIT_CLASSROOM_STUDENTS_DETAILS_FAILURE =
	"EDIT_CLASSROOM_STUDENTS_DETAILS_FAILURE";

export const ERASE_EDIT_STUDENT_DETAILS_MESSAGE =
	"ERASE_EDIT_STUDENT_DETAILS_MESSAGE";

export const ADD_STUDENTS_IN_BULK = "ADD_STUDENTS_IN_BULK";
export const ADD_STUDENTS_IN_BULK_SUCCESS = "ADD_STUDENTS_IN_BULK_SUCCESS";
export const ADD_STUDENTS_IN_BULK_FAILURE = "ADD_STUDENTS_IN_BULK_FAILURE";
export const GET_ADDED_QUESTIONS_DATA = "GET_ADDED_QUESTIONS_DATA";
export const GET_ADDED_QUESTIONS_DATA_SUCCESS =
	"GET_ADDED_QUESTIONS_DATA_SUCCESS";
export const GET_ADDED_QUESTIONS_DATA_FAILURE =
	"GET_ADDED_QUESTIONS_DATA_FAILURE";
export const GET_RESULT_FOR_EACH_TEST = "GET_RESULT_FOR_EACH_TEST";
export const GET_RESULT_FOR_EACH_TEST_SUCCESS =
	"GET_RESULT_FOR_EACH_TEST_SUCCESS";
export const GET_RESULT_FOR_EACH_TEST_FAILURE =
	"GET_RESULT_FOR_EACH_TEST_FAILURE";

export const UPDATE_TEST_DATA = "UPDATE_TEST_DATA";
export const UPDATE_TEST_DATA_SUCCESS = "UPDATE_TEST_DATA_SUCCESS";
export const UPDATE_TEST_DATA_FAILURE = "UPDATE_TEST_DATA_FAILURE";

export const CHANGE_PAGE_FOR_MANAGE_TEST = "CHANGE_PAGE_FOR_MANAGE_TEST";
export const CHANGE_ROWS_PER_PAGE_FOR_MANAGE_TEST =
	"CHANGE_ROWS_PER_PAGE_FOR_MANAGE_TEST";

export const GET_PROFILE_ADMIN = "GET_PROFILE_ADMIN";
export const GET_PROFILE_ADMIN_SUCCESS = "GET_PROFILE_ADMIN_SUCCESS";
export const GET_PROFILE_ADMIN_FAILURE = "GET_PROFILE_ADMIN_FAILURE";

export const EDIT_PROFILE_ADMIN = "EDIT_PROFILE_ADMIN";
export const EDIT_PROFILE_ADMIN_SUCCESS = "EDIT_PROFILE_ADMIN_SUCCESS";
export const EDIT_PROFILE_ADMIN_FAILURE = "EDIT_PROFILE_ADMIN_FAILURE";

export const ERASE_MSG_AND_ERROR_PROFILE_EDIT_ADMIN =
	"ERASE_MSG_AND_ERROR_PROFILE_EDIT_ADMIN";
export const ERASE_MSG_AND_ERROR_EDIT_TEST = "ERASE_MSG_AND_ERROR_EDIT_TEST";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";

export const GET_CLASSROOMS_NON_AUTHENTICATED =
	"GET_CLASSROOMS_NON_AUTHENTICATED";
export const GET_CLASSROOMS_NON_AUTHENTICATED_SUCCESS =
	"GET_CLASSROOMS_NON_AUTHENTICATED_SUCCESS";
export const GET_CLASSROOMS_NON_AUTHENTICATED_FAILURE =
	"GET_CLASSROOMS_NON_AUTHENTICATED_FAILURE";

export const REGISTER_STUDENT = "REGISTER_STUDENT";
export const REGISTER_STUDENT_SUCCESS = "REGISTER_STUDENT_SUCCESS";
export const REGISTER_STUDENT_FAILURE = "REGISTER_STUDENT_FAILURE";

export const TRIGGER_OTP = "TRIGGER_OTP";
export const TRIGGER_OTP_SUCCESS = "TRIGGER_OTP_SUCCESS";
export const TRIGGER_OTP_FAILURE = "TRIGGER_OTP_FAILURE";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const ERASE_AUTHENTICATION_ERROR = "ERASE_AUTHENTICATION_ERROR";

export const GET_TEST_FROM_SEARCH_TEXT = "GET_TEST_FROM_SEARCH_TEXT";
export const GET_TEST_FROM_SEARCH_TEXT_SUCCESS =
	"GET_TEST_FROM_SEARCH_TEXT_SUCCESS";
export const GET_TEST_FROM_SEARCH_TEXT_FAILURE =
	"GET_TEST_FROM_SEARCH_TEXT_FAILURE";

export const ERASE_SEARCH_ERROR = "ERASE_SEARCH_ERROR";

export const GET_TEST_SECTION = "GET_TEST_SECTION";
export const GET_TEST_SECTION_SUCCESS = "GET_TEST_SECTION_SUCCESS";
export const GET_TEST_SECTION_FAILURE = "GET_TEST_SECTION_FAILURE";

export const CREATE_TEST_SECTION = "CREATE_TEST_SECTION";
export const CREATE_TEST_SECTION_SUCCESS = "CREATE_TEST_SECTION_SUCCESS";
export const CREATE_TEST_SECTION_FAILURE = "CREATE_TEST_SECTION_FAILURE";

export const CLEAR_CREATE_SECTION_MESSGAE_AND_ERROR =
	"CLEAR_CREATE_SECTION_MESSGAE_AND_ERROR";

export const SELECT_SECTION_FROM_A_FIELD = "SELECT_SECTION_FROM_A_FIELD";
export const GET_TEST_FROM_SEARCH_TEXT_ADMIN =
	"GET_TEST_FROM_SEARCH_TEXT_ADMIN";
export const GET_TEST_FROM_SEARCH_TEXT_SUCCESS_ADMIN =
	"GET_TEST_FROM_SEARCH_TEXT_SUCCESS_ADMIN";
export const GET_TEST_FROM_SEARCH_TEXT_FAILURE_ADMIN =
	"GET_TEST_FROM_SEARCH_TEXT_FAILURE_ADMIN";

export const ERASE_SEARCH_ERROR_ADMIN = "ERASE_SEARCH_ERROR_ADMIN";

export const GET_SEARCH_TEST_COUNT_ADMIN = "GET_SEARCH_TEST_COUNT_ADMIN";
export const GET_SEARCH_TEST_COUNT_ADMIN_SUCCESS =
	"GET_SEARCH_TEST_COUNT_ADMIN_SUCCESS";
export const GET_SEARCH_TEST_COUNT_ADMIN_FAILURE =
	"GET_SEARCH_TEST_COUNT_ADMIN_FAILURE";

export const RESET_TEST_MODAL = "RESET_TEST_MODAL";
export const RESET_TEST_MODAL_SUCCESS = "RESET_TEST_MODAL_SUCCESS";
export const RESET_TEST_MODAL_FAILURE = "RESET_TEST_MODAL_FAILURE";
export const ERASE_RESET_TEST_MESSAGE_AND_ERROR =
	"ERASE_RESET_TEST_MESSAGE_AND_ERROR";
export const GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT =
	"GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT";
export const GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_SUCCESS =
	"GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_SUCCESS";
export const GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_FAILURE =
	"GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_FAILURE";

export const GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT =
	"GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT";
export const GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_SUCCESS =
	"GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_SUCCESS";
export const GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_FAILURE =
	"GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT__FAILURE";

export const SHOW_INCOMPLETE_DETAILS_MODAL = "SHOW_INCOMPLETE_DETAILS_MODAL";

export const GET_INCOMPLETE_STUDENT_DATA = "GET_INCOMPLETE_STUDENT_DATA";
export const GET_INCOMPLETE_STUDENT_DATA_SUCCESS =
	"GET_INCOMPLETE_STUDENT_DATA_SUCCESS";
export const GET_INCOMPLETE_STUDENT_DATA_FAILURE =
	"GET_INCOMPLETE_STUDENT_DATA_FAILURE";

export const SEND_COMPLETE_STUDENT_DATA = "SEND_COMPLETE_STUDENT_DATA";
export const SEND_COMPLETE_STUDENT_DATA_SUCCESS =
	"SEND_COMPLETE_STUDENT_DATA_SUCCESS";
export const SEND_COMPLETE_STUDENT_DATA_FAILURE =
	"SEND_COMPLETE_STUDENT_DATA_FAILURE";

export const CLEAR_PAST_USER_GIVING_TEST_DATA =
	"CLEAR_PAST_USER_GIVING_TEST_DATA";

//new result Page
export const CLEAR_PAST_DATA_OF_TEST_RESULT = "CLEAR_PAST_DATA_OF_TEST_RESULT";

export const ERASE_ERROR_MESSAGE = "ERASE_ERROR_MESSAGE";

export const CHANGE_SECTION_TABS_SELECTED = "CHANGE_SECTION_TABS_SELECTED";

export const GET_COMMON_PART_OF_TEST_RESULT = "GET_COMMON_PART_OF_TEST_RESULT";
export const GET_COMMON_PART_OF_TEST_RESULT_SUCCESS =
	"GET_COMMON_PART_OF_TEST_RESULT_SUCCESS";
export const GET_COMMON_PART_OF_TEST_RESULT_FAILURE =
	"GET_COMMON_PART_OF_TEST_RESULT_FAILURE";

export const GET_OVERVIEW_PART_STATS_DATA = "GET_OVERVIEW_PART_STATS_DATA";
export const GET_OVERVIEW_PART_STATS_DATA_SUCCESS =
	"GET_OVERVIEW_PART_STATS_DATA_SUCCESS";
export const GET_OVERVIEW_PART_STATS_DATA_FAILURE =
	"GET_OVERVIEW_PART_STATS_DATA_FAILURE";

export const GET_MARKS_ANALYSIS = "GET_MARKS_ANALYSIS";
export const GET_MARKS_ANALYSIS_SUCCESS = "GET_MARKS_ANALYSIS_SUCCESS";
export const GET_MARKS_ANALYSIS_FAILURE = "GET_MARKS_ANALYSIS_FAILURE";

export const GET_TEST_RESULT_ACCURACY = "GET_TEST_RESULT_ACCURACY";
export const GET_TEST_RESULT_ACCURACY_SUCCESS =
	"GET_TEST_RESULT_ACCURACY_SUCCESS";
export const GET_TEST_RESULT_ACCURACY_FAILURE =
	"GET_TEST_RESULT_ACCURACY_FAILURE";

export const GET_TEST_RESULT_ACCURACY_SECTION_WISE =
	"GET_TEST_RESULT_ACCURACY_SECTION_WISE,";
export const GET_TEST_RESULT_ACCURACY_SECTION_WISE_SUCCESS =
	"GET_TEST_RESULT_ACCURACY_SECTION_WISE_SUCCESS,";
export const GET_TEST_RESULT_ACCURACY_SECTION_WISE_FAILURE =
	"GET_TEST_RESULT_ACCURACY_SECTION_WISE_FAILURE";

export const GET_FULL_PAPER_PEER_COMPARISON = "GET_FULL_PAPER_PEER_COMPARISON";
export const GET_FULL_PAPER_PEER_COMPARISON_SUCCESS =
	"GET_FULL_PAPER_PEER_COMPARISON_SUCCESS";
export const GET_FULL_PAPER_PEER_COMPARISON_FAILURE =
	"GET_FULL_PAPER_PEER_COMPARISON_FAILURE";
export const GET_SECTION_WISE_PEER_COMPARISON =
	"GET_SECTION_WISE_PEER_COMPARISON";

export const GET_SECTION_WISE_PEER_COMPARISON_SUCCESS =
	"GET_SECTION_WISE_PEER_COMPARISON_SUCCESS";
export const GET_SECTION_WISE_PEER_COMPARISON_FAILURE =
	"GET_SECTION_WISE_PEER_COMPARISON_FAILURE";

export const GET_TOPPERS = "GET_TOPPERS";
export const GET_TOPPERS_SUCCESS = "GET_TOPPERS_SUCCESS";
export const GET_TOPPERS_FAILURE = "GET_TOPPERS_FAILURE";

export const GET_TOPPERS_SECTION_WISE = "GET_TOPPERS_SECTION_WISE";
export const GET_TOPPERS_SECTION_WISE_SUCCESS =
	"GET_TOPPERS_SECTION_WISE_SUCCESS";
export const GET_TOPPERS_SECTION_WISE_FAILURE =
	"GET_TOPPERS_SECTION_WISE_FAILURE";

//NOT CHECKED

export const GET_PERFORMANCE_ANALYSIS = "GET_PERFORMANCE_ANALYSIS";
export const GET_PERFORMANCE_ANALYSIS_SUCCESS =
	"GET_PERFORMANCE_ANALYSIS_SUCCESS";
export const GET_PERFORMANCE_ANALYSIS_FAILURE =
	"GET_PERFORMANCE_ANALYSIS_FAILURE";

export const CLEAR_OVERALL_RESULT_PAGE_DATA = "CLEAR_OVERALL_RESULT_PAGE_DATA";

export const GET_ANALYSIS_OVERVIEW = "GET_ANALYSIS_OVERVIEW";
export const GET_ANALYSIS_OVERVIEW_SUCCESS = "GET_ANALYSIS_OVERVIEW_SUCCESS";
export const GET_ANALYSIS_OVERVIEW_FAILURE = "GET_ANALYSIS_OVERVIEW_FAILURE";
export const GET_SEARCHED_STUDENT = "GET_SEARCHED_STUDENT";
export const GET_SEARCHED_STUDENT_SUCCESS = "GET_SEARCHED_STUDENT_SUCCESS";
export const GET_SEARCHED_STUDENT_FAILURE = "GET_SEARCHED_STUDENT_FAILURE";
