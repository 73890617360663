import React from "react";

export default function EnrollmentStatus(props) {
	return (
		<div className="row">
			<div className="alert-success text-center jumbotron col-md-8 offset-md-2 mt-5">
				<div className="row">
					<div className="col-md-12">
						<h1>{props.enrolledStatus}</h1>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-md-6 mb-3">
						<button
							type="button"
							className="btn btn-sm btn-primary"
							onClick={() => (window.location.href = "/manage-tests")}
						>
							Go to home
						</button>
					</div>
					<div className="col-md-6">
						<button
							type="button"
							className="btn btn-sm btn-secondary"
							onClick={() => window.location.reload()}
						>
							Enroll more
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
