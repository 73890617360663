import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	container: {},
	root: {
		flexGrow: 1,
		textAlign: "center",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		minHeight: "60px",
	},
	btn: {
		margin: "1%",
		//position: "absolute",
		color: "white",
		backgroundColor: "#fd9f01",
		"&:hover": {
			backgroundColor: "#fd9f01",
		},
	},
	title: {
		justifyContent: "center",
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",

		"& .modal-content": {
			overflow: "auto",
		},
		"& .modal-dialog": {
			maxWidth: "45rem",
		},
		padding: "0 !important",
	},
	addBtn: {
		color: "white",
		backgroundColor: "#fd9f01",
		width: "auto",
		"&:hover": {
			backgroundColor: "#fd9f01",
		},
	},
	cancelBtn: {
		color: "white",
		backgroundColor: "#dc3545",
		width: "auto",
		"&:hover": {
			backgroundColor: "#dc3545",
		},
	},
	formBody: {
		width: "90%",
		maxWidth: "600px",
		display: "flex",
		padding: "2%",
		background: "white",
		margin: "2% auto",
	},
	modalBody: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		//justifyContent: "center",
		alignItems: "center",
		"& .MuiFormControl-root ": {
			paddingBottom: "20px",
		},
		// "& .MuiOutlinedInput-root": {
		// 	width: "250px",
		// },
		"& label.MuiFormControlLabel-root": {
			marginBottom: 0,
		},
	},
	duration: {
		display: "flex",
		// "& .MuiOutlinedInput-root": {
		// 	width: "90px",
		// },
		" & .MuiFormControl-root ": {
			paddingRight: "20px",
		},
	},
	check: {
		justifyContent: "flex-start",
		width: "280px",
	},
	modalBodyReport: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		"& .MuiFormControl-root ": {
			paddingBottom: "20px",
		},
		"& label.MuiFormControlLabel-root": {
			marginBottom: 0,
		},
	},

	modalBodyTest: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		//justifyContent: "center",
		alignItems: "center",
		"& .MuiFormControl-root ": {
			paddingBottom: "20px",
		},
		"& label.MuiFormControlLabel-root": {
			marginBottom: 0,
		},
		"& div ": {
			width: "90%",
			maxWidth: "30rem",
		},
		[theme.breakpoints.down("sm")]: {
			"& div ": {
				width: "100%",
				maxWidth: "30rem",
			},
		},
	},
	modalWrapper: {
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	pagination: {
		backgroundColor: "white",
		width: "100%",
		bottom: 0,
		position: "fixed",
		zIndex: "0",
	},
	modalBodyOtp: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		//justifyContent: "center",
		alignItems: "center",
		// "& .MuiFormControl-root ": {
		// 	paddingBottom: "20px",
		// },
		// "& .MuiOutlinedInput-root": {
		// 	width: "250px",
		// },
		"& label.MuiFormControlLabel-root": {
			marginBottom: 0,
		},
		"& .MuiGrid-grid-md-6": {
			margin: "auto",
		},
	},
	modalBodyTestEdit: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		//justifyContent: "center",
		alignItems: "center",
		"& .MuiFormControl-root ": {
			paddingBottom: "20px",
		},
		"& label.MuiFormControlLabel-root": {
			marginBottom: 0,
		},
		"& div ": {
			width: "100%",
			maxWidth: "30rem",
		},
		[theme.breakpoints.down("sm")]: {
			"& div ": {
				width: "100%",
				maxWidth: "30rem",
			},
		},
	},
	searchWrapper: {
		position: "relative",
		top: "-1.5rem",
		padding: "0 1rem",
		maxWidth: "30rem",
		width: "100%",
		margin: "auto",
		[theme.breakpoints.down("sm")]: {
			position: "static",
			padding: "1rem 2rem 1rem 1rem",
		},

		"& form": {
			margin: "0 auto",
		},
	},
	userName: {
		fontWeight: "bold",
		fontSize: "1.75rem",
		overflow: "hidden",
		padding: "0 15px 25px 15px",
		overflowWrap: "break-word",
		[theme.breakpoints.down("sm")]: {
			padding: "0 15px 15px 15px",
		},
	},
	noTest: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "25vh",
		textAlign: "center",
	},
	timer: {
		display: "flex",
		alignItems: "center",
	},
}));
