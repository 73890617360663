const {
	ADD_TEACHER,
	ADD_TEACHER_SUCCESS,
	ADD_TEACHER_FAILURE,
	ERASE_TEACHER_ADD_DATA,
} = require("../actions/type");

const initialState = {
	loading: false,
	data: {},
	error: "",
};

const teacherReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ADD_TEACHER:
			return {
				...state,
				loading: true,
				data: {},
				error: "",
			};

		case ADD_TEACHER_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};

		case ADD_TEACHER_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case ERASE_TEACHER_ADD_DATA:
			return { ...initialState };

		default:
			return state;
	}
};
export default teacherReducer;
