import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ExitToApp, AccountCircle } from "@material-ui/icons";
import { connect } from "react-redux";
import { logout } from "../../../actions/authentication";
import { makeStyles, Typography } from "@material-ui/core";
import { noInternetImage, parikshagruhLogo } from "../../../img/imageLinks";

import { useHistory } from "react-router-dom";

import Loader from "./Loader";

import styles from "./Navbar.module.css";

const useStyle = makeStyles(() => ({
	root: {
		height: "3rem",
		width: "3rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#fd9f01",
	},
}));

const Navbar = ({ logout, validated, role, logoutLoading, logoutState }) => {
	const location = useLocation();
	const classes = useStyle();
	const path = location.pathname;

	const history = useHistory();

	const [showNavbar, setShowNavbar] = useState(true);

	useEffect(() => {
		// if (logoutState && role=== "STUDENT") {
		// 	history.push({
		// 		pathname: "/login",
		// 	});
		// 	return;
		// }
		// //FOR ADMIN
		// else if (logoutState) {
		// 	history.push({
		// 		pathname: "/institute-login",
		// 	});
		// 	return;
		// }

		if (logoutState) {
			//const tempRole = localStorage.getItem("role");
			localStorage.removeItem("role");
			// if (tempRole === "STUDENT") {
			// 	history.push({
			// 		pathname: "/login",
			// 	});
			// 	return;
			// }
			// //FOR ADMIN
			// else {
			// 	history.push({
			// 		pathname: "/institute-login",
			// 	});
			// 	return;
			// }
			localStorage.clear();
			sessionStorage.clear();
			history.push({
				pathname: "/login",
			});
		}
	}, [logoutState, history, role]);

	useEffect(() => {
		const checkUserGivingTest = () => {
			let path = window.location.pathname;
			//console.log("path", path);
			if (path.slice(0, 6) === "/test/") {
				setShowNavbar(false);
				return;
			} else if (path.slice(0, 15) === "/overall-result") {
				setShowNavbar(false);
				return;
			}

			setShowNavbar(true);
			return;
		};

		checkUserGivingTest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);

	const logoutUser = () => {
		logout();
	};
	const getLinks = () => {
		if (role === "STUDENT") {
			return (
				<Link to="/test">
					<img
						className={styles.brandImage}
						src={parikshagruhLogo}
						alt="Home"
					/>
					{/*<h1 className={styles.brandText}>ACHARYA ACADEMY</h1>*/}
				</Link>
			);
		} else if (role === "ADMIN") {
			return (
				<Link to="/manage-tests" className={styles.brand}>
					<img
						className={styles.brandImage}
						src={parikshagruhLogo}
						alt="Home"
					/>
				</Link>
			);
		}

		return (
			<Link to="/login" className={styles.brand}>
				<img className={styles.brandImage} src={parikshagruhLogo} alt="Home" />
			</Link>
		);
	};

	const SomeNavbar = () => {
		return (
			<Fragment>
				<div className="navbar-brand">{getLinks()}</div>

				<div className="nav-item dropdown">
					<a href="#!" className="d-block" data-toggle="dropdown">
						<AccountCircle className={classes.root} id="profile-icon" />
					</a>
					<div className="dropdown-menu dropdown-menu-left account-dropdown">
						{role === "STUDENT" && (
							<Link to="/student-profile" className="dropdown-item">
								Profile
							</Link>
						)}
						{role === "ADMIN" && (
							<Link to="/admin-profile" className="dropdown-item">
								Profile
							</Link>
						)}
						<button className="dropdown-item d-flex" onClick={logoutUser}>
							<Typography>Logout &nbsp;</Typography>
							<ExitToApp />
						</button>
					</div>
				</div>
			</Fragment>
		);
	};

	return (
		<Loader loading={logoutLoading}>
			{validated && showNavbar ? (
				<div className={path === "/" ? "landing" : "nonLanding"}>
					<header id="header">
						<nav className={`navbar navbar-expand-lg ${styles.navbar}`}>
							<SomeNavbar />
						</nav>
						{
							<img
								className="navbar-brand-img"
								src={noInternetImage}
								alt="No Internet"
								style={{ display: "none" }}
							/>
						}
					</header>
				</div>
			) : (
				<></>
			)}
		</Loader>
	);
};

const mapStateToProps = (state) => ({
	validated: state.authentication.validated,
	logoutState: state.authentication.logoutState,
	role: state.authentication.role,
	logoutLoading: state.authentication.logoutLoading,
});

const enhance = connect(mapStateToProps, {
	logout,
});

export default enhance(Navbar);
