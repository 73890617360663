const {
	CLEAR_PAST_DATA_OF_TEST_RESULT,
	ERASE_ERROR_MESSAGE,
	CHANGE_SECTION_TABS_SELECTED,
	GET_COMMON_PART_OF_TEST_RESULT,
	GET_COMMON_PART_OF_TEST_RESULT_SUCCESS,
	GET_COMMON_PART_OF_TEST_RESULT_FAILURE,
	GET_MARKS_ANALYSIS,
	GET_MARKS_ANALYSIS_SUCCESS,
	GET_MARKS_ANALYSIS_FAILURE,

	GET_TEST_RESULT_ACCURACY,
	GET_TEST_RESULT_ACCURACY_SUCCESS,
	GET_TEST_RESULT_ACCURACY_FAILURE,

	GET_TEST_RESULT_ACCURACY_SECTION_WISE,
	GET_TEST_RESULT_ACCURACY_SECTION_WISE_SUCCESS,
	GET_TEST_RESULT_ACCURACY_SECTION_WISE_FAILURE,

	GET_FULL_PAPER_PEER_COMPARISON,
	GET_FULL_PAPER_PEER_COMPARISON_SUCCESS,
	GET_FULL_PAPER_PEER_COMPARISON_FAILURE,

	GET_SECTION_WISE_PEER_COMPARISON,
	GET_SECTION_WISE_PEER_COMPARISON_SUCCESS,
	GET_SECTION_WISE_PEER_COMPARISON_FAILURE,

	GET_TOPPERS,
	GET_TOPPERS_SUCCESS,
	GET_TOPPERS_FAILURE,

	GET_TOPPERS_SECTION_WISE,
	GET_TOPPERS_SECTION_WISE_SUCCESS,
	GET_TOPPERS_SECTION_WISE_FAILURE,
	//NOT CHECKED
	GET_PERFORMANCE_ANALYSIS,
	GET_PERFORMANCE_ANALYSIS_SUCCESS,
	GET_PERFORMANCE_ANALYSIS_FAILURE,
	// CLEAR_OVERALL_RESULT_PAGE_DATA,
	GET_ANALYSIS_OVERVIEW,
	GET_ANALYSIS_OVERVIEW_SUCCESS,
	GET_ANALYSIS_OVERVIEW_FAILURE,

	GET_OVERVIEW_PART_STATS_DATA,
	GET_OVERVIEW_PART_STATS_DATA_SUCCESS,
	GET_OVERVIEW_PART_STATS_DATA_FAILURE,
} = require("../actions/type");

const initialState = {
	loading: false,
	error: "",
	tabsIndexSelected: 0,

	commonTestData: {},
	commonTestLoading: false,
	commonTestError: "",

	overViewStatsData: {},
	overViewStatsLoading: false,
	overViewStatsError: "",

	marksAnalysisData: [],
	marksAnalysisLoading: false,
	marksAnalysisError: "",

	accuracyLoading: false,
	accuracyError: "",
	accuracyData: {},

	accuracySectionWiseLoading: false,
	accuracySectionWiseError: "",
	accuracySectionWiseData: [],

	peerComparisonLoading: false,
	peerComparisonError: "",
	peerComparisonData: [],

	peerComparisonSectionWiseLoading: false,
	peerComparisonSectionWiseError: "",
	peerComparisonSectionWiseData: [],

	toppersLoading: false,
	toppersError: "",
	toppersData: [],

	toppersSectionWiseLoading: false,
	toppersSectionWiseError: "",
	toppersSectionWiseData: [],

	overviewData: [],
	overviewError: false,
	performanceData: {},
	performanceError: false,

	//NOT CHECKED

	data: {},
};

const studentResultReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR_PAST_DATA_OF_TEST_RESULT:
			return {
				...initialState,
			};

		case ERASE_ERROR_MESSAGE:
			return {
				...state,
				[payload]: "",
			};

		case CHANGE_SECTION_TABS_SELECTED:
			return {
				...state,
				tabsIndexSelected: payload,
			};

		case GET_COMMON_PART_OF_TEST_RESULT:
			return {
				...state,
				commonTestLoading: true,
				commonTestError: "",
			};
		case GET_COMMON_PART_OF_TEST_RESULT_SUCCESS:
			return {
				...state,
				commonTestLoading: false,
				commonTestData: payload,
			};
		case GET_COMMON_PART_OF_TEST_RESULT_FAILURE:
			return {
				...state,
				commonTestLoading: false,
				commonTestError:
					payload || "Something went wrong, Please try again later",
			};

		case GET_OVERVIEW_PART_STATS_DATA:
			return {
				...state,
				overViewStatsLoading: true,
				overViewStatsError: "",
			};
		case GET_OVERVIEW_PART_STATS_DATA_SUCCESS:
			return {
				...state,
				overViewStatsLoading: false,
				overViewStatsData: payload,
			};
		case GET_OVERVIEW_PART_STATS_DATA_FAILURE:
			return {
				...state,
				overViewStatsLoading: false,
				overViewStatsError:
					payload || "Something went wrong, Please try again later",
			};

		case GET_MARKS_ANALYSIS:
			return {
				...state,
				marksAnalysisLoading: true,
				marksAnalysisError: "",
			};
		case GET_MARKS_ANALYSIS_SUCCESS:
			return {
				...state,
				marksAnalysisLoading: false,
				marksAnalysisData: payload,
			};
		case GET_MARKS_ANALYSIS_FAILURE:
			return {
				...state,
				marksAnalysisLoading: false,
				marksAnalysisError:
					payload || "Something went wrong, Please try again later",
			};

		case GET_TEST_RESULT_ACCURACY:
			return {
				...state,
				accuracyLoading: true,
				accuracyError: "",
			};
		case GET_TEST_RESULT_ACCURACY_SUCCESS:
			return {
				...state,
				accuracyLoading: false,
				accuracyData: payload,
			};
		case GET_TEST_RESULT_ACCURACY_FAILURE:
			return {
				...state,
				accuracyLoading: false,
				accuracyError:
					payload || "Something went wrong, Please try again later",
			};
		case GET_TEST_RESULT_ACCURACY_SECTION_WISE:
			return {
				...state,
				accuracySectionWiseLoading: true,
				accuracyError: "",
			};
		case GET_TEST_RESULT_ACCURACY_SECTION_WISE_SUCCESS:
			return {
				...state,
				accuracySectionWiseLoading: false,
				accuracySectionWiseData: payload,
			};
		case GET_TEST_RESULT_ACCURACY_SECTION_WISE_FAILURE:
			return {
				...state,
				accuracySectionWiseLoading: false,
				accuracySectionWiseError:
					payload || "Something went wrong, Please try again later",
			};

		case GET_FULL_PAPER_PEER_COMPARISON:
			return {
				...state,
				peerComparisonLoading: true,
				peerComparisonError: "",
			};
		case GET_FULL_PAPER_PEER_COMPARISON_SUCCESS:
			return {
				...state,
				peerComparisonLoading: false,
				peerComparisonData: payload,
			};
		case GET_FULL_PAPER_PEER_COMPARISON_FAILURE:
			return {
				...state,
				peerComparisonLoading: false,
				peerComparisonError:
					payload || "Something went wrong, Please try again later",
			};
		case GET_SECTION_WISE_PEER_COMPARISON:
			return {
				...state,
				peerComparisonSectionWiseLoading: true,
				peerComparisonSectionWiseError: "",
			};
		case GET_SECTION_WISE_PEER_COMPARISON_SUCCESS:
			return {
				...state,
				peerComparisonSectionWiseLoading: false,
				peerComparisonSectionWiseData: payload,
			};
		case GET_SECTION_WISE_PEER_COMPARISON_FAILURE:
			return {
				...state,
				peerComparisonSectionWiseLoading: false,
				peerComparisonSectionWiseError:
					payload || "Something went wrong, Please try again later",
			};

		case GET_TOPPERS:
			return {
				...state,
				toppersLoading: true,
				toppersError: "",
			};
		case GET_TOPPERS_SUCCESS:
			return {
				...state,
				toppersLoading: false,
				toppersData: payload,
			};
		case GET_TOPPERS_FAILURE:
			return {
				...state,
				toppersLoading: false,
				toppersError: payload || "Something went wrong, Please try again later",
			};
		case GET_TOPPERS_SECTION_WISE:
			return {
				...state,
				toppersSectionWiseLoading: true,
			};
		case GET_TOPPERS_SECTION_WISE_SUCCESS:
			return {
				...state,
				toppersSectionWiseLoading: false,
				toppersSectionWiseData: payload,
			};
		case GET_TOPPERS_SECTION_WISE_FAILURE:
			return {
				...state,
				toppersSectionWiseLoading: false,
				toppersSectionWiseError:
					payload || "Something went wrong, Please try again later",
			};

		//NOT CHECKED

		case GET_PERFORMANCE_ANALYSIS:
			return {
				...state,
				loading: true,
				performanceError: false,
			};

		case GET_PERFORMANCE_ANALYSIS_SUCCESS:
			return {
				...state,
				performanceData: payload,
				loading: false,
			};

		case GET_PERFORMANCE_ANALYSIS_FAILURE:
			return {
				...state,
				performanceError:
					payload || "Something went wrong, Please try again later",
				loading: false,
			};

		case GET_ANALYSIS_OVERVIEW:
			return {
				...state,
				loading: true,
				overviewError: false,
			};

		case GET_ANALYSIS_OVERVIEW_SUCCESS:
			return {
				...state,
				overviewData: payload,
				loading: false,
			};

		case GET_ANALYSIS_OVERVIEW_FAILURE:
			return {
				...state,
				overviewError:
					payload || "Something went wrong, Please try again later",
				loading: false,
			};

		// case CLEAR_OVERALL_RESULT_PAGE_DATA:
		// 	return {
		// 		...state,
		// 		overviewData: [],
		// 		overviewError: false,
		// 		performanceData: {},
		// 		performanceError: false,
		// 	};

		default:
			return state;
	}
};

export default studentResultReducer;
