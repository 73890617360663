const {
	LOGIN_FAILED_,
	LOGIN_SUCCESS_,
	REQ_LOGIN_,
	LOGOUT,
	LOGOUT_SUCCESS,
	LOGOUT_FAILURE,
	GET_STUDENT,
	GET_STUDENT_SUCCESS,
	GET_STUDENT_FAILED,
	SET_AUTH_TOKEN,
	GET_ACCESSTOKEN,
	GET_ACCESSTOKEN_SUCCESS,
	GET_ACCESSTOKEN_FAILED,
	SOCIAL_LOGIN,
	SOCIAL_LOGIN_SUCCESS,
	SOCIAL_LOGIN_FAILURE,
	GET_CLASSROOMS_NON_AUTHENTICATED,
	GET_CLASSROOMS_NON_AUTHENTICATED_SUCCESS,
	GET_CLASSROOMS_NON_AUTHENTICATED_FAILURE,
	REGISTER_STUDENT,
	REGISTER_STUDENT_SUCCESS,
	REGISTER_STUDENT_FAILURE,
	TRIGGER_OTP,
	TRIGGER_OTP_SUCCESS,
	TRIGGER_OTP_FAILURE,
	VALIDATE_OTP,
	VALIDATE_OTP_SUCCESS,
	VALIDATE_OTP_FAILURE,
	ERASE_AUTHENTICATION_ERROR,
} = require("../actions/type");

const initialState = {
	loading: false,
	logoutLoading: false,
	validated: false,
	data: {},
	error: "",
	role: "",
	token: "",
	refreshToken: "",
	accessToken: "",
	logoutState: false,
	classroomData: [],
	showOptModalFlag: false, //will make it true if REGISTER_STUDENT_SUCCESS otherwise no point to show otp modal
};

const authenticationReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case REQ_LOGIN_:
			return {
				...initialState,
				loading: true,
			};

		case LOGIN_SUCCESS_:
			const { user, accessToken, refreshToken } = payload;
			localStorage.setItem("accessToken", accessToken);
			localStorage.setItem("role", user?.role);
			localStorage.setItem("refreshToken", refreshToken);
			localStorage.setItem("userName", user?.name);
			//localStorage.setItem("lastRefreshTime", Date.now());

			return {
				...initialState,
				loading: false,
				validated: true,
				role: user?.role,
				accessToken,
				refreshToken,
			};

		case LOGIN_FAILED_:
			//console.log("inside login failed");
			return {
				...initialState,
				validated: false,
				loading: false,
				error: action.payload || "Something went wrong, Please try again later",
			};

		case LOGOUT:
			// return initialState;
			return { ...state, logoutLoading: true };

		case LOGOUT_SUCCESS:
			localStorage.removeItem("accessToken");
			//role will be removed inside navbar as neede to go to respective login page
			//localStorage.removeItem("role");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("lastRefreshTime");
			return {
				initialState,
				logoutLoading: false,
				logoutState: true,
			};

		case LOGOUT_FAILURE:
			localStorage.removeItem("accessToken");
			//role will be removed inside navbar as neede to go to respective login page
			//localStorage.removeItem("role");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("lastRefreshTime");
			return {
				initialState,
				logoutLoading: false,
				logoutState: true,
				//WILL NOT SHOW ERROR DURING LOGOUT
				//error: action.payload || "Something went wrong, Please try again later",
			};

		case SET_AUTH_TOKEN: {
			const { accessToken, refreshToken, role } = payload;
			return {
				...state,
				validated: true,
				accessToken,
				refreshToken,
				role,
			};
		}

		case GET_STUDENT:
			return {
				...state,
				loading: true,
			};

		case GET_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case GET_STUDENT_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case GET_ACCESSTOKEN:
			return {
				...state,
				loading: true,
			};
		case GET_ACCESSTOKEN_SUCCESS:
			//console.log("payloaddddd", payload);
			//const { accessToken, refreshToken } = payload;
			localStorage.setItem("accessToken", payload.accessToken);
			localStorage.setItem("refreshToken", payload.refreshToken);

			return {
				...state,
				loading: false,
				accessToken: payload.accessToken,
				refreshToken: payload.refreshToken,
			};

		case GET_ACCESSTOKEN_FAILED:
			return {
				...state,
				loading: false,
				validated: false, //as refresh token also might have become invalid
				error: payload || "Something went wrong, Please try again later",
			};
		case SOCIAL_LOGIN:
			return {
				...initialState,
				loading: true,
			};
		case SOCIAL_LOGIN_SUCCESS:
			//console.log("payload", payload);
			localStorage.setItem("accessToken", payload?.accessToken);
			localStorage.setItem("role", payload?.userDTO?.role);
			localStorage.setItem("refreshToken", payload?.refreshToken);

			return {
				...initialState,
				loading: false,
				validated: true,
				role: payload?.userDTO?.role,
				accessToken: payload?.accessToken,
				refreshToken: payload?.refreshToken,
			};
		case SOCIAL_LOGIN_FAILURE:
			return {
				...initialState,
				validated: false,
				loading: false,
				error: action.payload || "Something went wrong, Please try again later",
			};
		case GET_CLASSROOMS_NON_AUTHENTICATED:
			return {
				...state,
				loading: true,
			};
		case GET_CLASSROOMS_NON_AUTHENTICATED_SUCCESS:
			//console.log("payload", payload);
			return {
				...state,
				loading: false,
				classroomData: payload,
			};
		case GET_CLASSROOMS_NON_AUTHENTICATED_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case REGISTER_STUDENT:
			return {
				...state,
				loading: true,
				showOptModalFlag: false,
				error: "",
			};
		case REGISTER_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				showOptModalFlag: true,
			};
		case REGISTER_STUDENT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case TRIGGER_OTP:
			return {
				...state,
				//loading: true,
			};
		case TRIGGER_OTP_SUCCESS:
			return {
				...state,
				//loading: false,
				data: payload,
			};
		case TRIGGER_OTP_FAILURE:
			return {
				...state,
				//loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case VALIDATE_OTP:
			return {
				...state,
				loading: true,
			};
		case VALIDATE_OTP_SUCCESS:
			console.log("payloadOpt", payload);
			localStorage.setItem("accessToken", payload?.accessToken);
			localStorage.setItem("role", payload?.user?.role);
			localStorage.setItem("refreshToken", payload?.refreshToken);
			//localStorage.setItem("lastRefreshTime", Date.now());

			return {
				...state,
				loading: false,
				validated: true,
				role: payload?.user?.role,
				accessToken: payload?.accessToken,
				refreshToken: payload?.refreshToken,
			};
		case VALIDATE_OTP_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case ERASE_AUTHENTICATION_ERROR:
			return {
				...state,
				error: "",
			};
		default:
			return state;
	}
};

export default authenticationReducer;
