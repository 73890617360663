const {
	CHANGE_STANDARD_ID,
	CHANGE_SUBJECT_ID,
	CHANGE_CHAPTER_ID,
	CHANGE_TOPIC_ID,
	CHANGE_DIFFICULTY_LEVEL_ID,
	CHANGE_QUESTION_TYPE_ID,
	RESET_FILTER,
	CHANGE_CORRECT_ANSWER_MARKS,
	CHANGE_WRONG_ANSWER_MARKS,
	ADD_SELECTED_QUESTION_DATA,
	EMPTY_SELECTED_QUESTION_DATA,
	EMPTY_PAST_FRONTEND_STATE,
	GET_ADDED_QUESTIONS_DATA,
	GET_ADDED_QUESTIONS_DATA_SUCCESS,
	GET_ADDED_QUESTIONS_DATA_FAILURE,
	GET_TEST_SECTION,
	GET_TEST_SECTION_SUCCESS,
	GET_TEST_SECTION_FAILURE,
	CREATE_TEST_SECTION,
	CREATE_TEST_SECTION_SUCCESS,
	CREATE_TEST_SECTION_FAILURE,
	SELECT_SECTION_FROM_A_FIELD,
	CLEAR_CREATE_SECTION_MESSGAE_AND_ERROR,
} = require("../actions/type");

const initialState = {
	loading: false,
	error: "",
	page: 0,
	rowsPerPage: 10,
	offset: 0,
	standardId: "",
	subjectId: "",
	chapterId: "",
	topicId: "",
	difficultyLevelId: "",
	questionTypeId: "",
	correctAnswerMarks: "",
	wrongAnswerMarks: "",
	//will store questionIds of various Questions which are selected by admin,
	//so that even the page changes, data is stored.
	selectedQuestionData: {},

	addedQuestionsData: {},
	sectionsData: [],
	addSectionMessage: "",
	sectionSelected: "",
};

const addQuestionReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CHANGE_STANDARD_ID:
			return {
				...state,
				standardId: payload,
			};
		case CHANGE_SUBJECT_ID:
			return {
				...state,
				subjectId: payload,
			};
		case CHANGE_CHAPTER_ID:
			return {
				...state,
				chapterId: payload,
			};
		case CHANGE_TOPIC_ID:
			return {
				...state,
				topicId: payload,
			};
		case CHANGE_DIFFICULTY_LEVEL_ID:
			return {
				...state,
				difficultyLevelId: payload,
			};
		case CHANGE_QUESTION_TYPE_ID:
			return {
				...state,
				questionTypeId: payload,
			};

		case RESET_FILTER:
			return {
				...state,
				standardId: "",
				subjectId: "",
				chapterId: "",
				topicId: "",
				difficultyId: "",
				questionTypeId: "",
			};

		case CHANGE_CORRECT_ANSWER_MARKS:
			return {
				...state,
				correctAnswerMarks: payload,
			};

		case CHANGE_WRONG_ANSWER_MARKS:
			return {
				...state,
				wrongAnswerMarks: payload,
			};

		case ADD_SELECTED_QUESTION_DATA:
			return {
				...state,
				selectedQuestionData: payload,
			};
		case EMPTY_SELECTED_QUESTION_DATA:
			return {
				...state,
				selectedQuestionData: [],
			};
		case EMPTY_PAST_FRONTEND_STATE:
			return {
				...initialState,
			};
		case GET_ADDED_QUESTIONS_DATA:
			return {
				...state,
				loading: true,
				addedQuestionsData: {},
				error: "",
			};
		case GET_ADDED_QUESTIONS_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				addedQuestionsData: payload,
			};
		case GET_ADDED_QUESTIONS_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_TEST_SECTION:
			return {
				...state,
				loading: true,
				sectionsData: [],
				error: "",
			};
		case GET_TEST_SECTION_SUCCESS:
			return {
				...state,
				loading: false,
				sectionsData: payload,
			};
		case GET_TEST_SECTION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case CREATE_TEST_SECTION:
			return {
				...state,
				loading: true,
				addSectionMessage: "",
				error: "",
			};
		case CREATE_TEST_SECTION_SUCCESS:
			return {
				...state,
				loading: false,
				addSectionMessage: payload,
			};
		case CREATE_TEST_SECTION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case SELECT_SECTION_FROM_A_FIELD:
			return {
				...state,
				sectionSelected: payload,
			};

		case CLEAR_CREATE_SECTION_MESSGAE_AND_ERROR:
			return {
				...state,
				addSectionMessage: "",
				error: "",
			};
		default:
			return state;
	}
};

export default addQuestionReducer;
