import { lazy } from "react";

import EnrollForm from "./components/test-series/EnrollStudent/enrollForm";

const NotFound = lazy(() =>
	import("./components/test-series/genericComponents/NotFound")
);
const StudentLogin = lazy(() =>
	import("./components/test-series/Auth/StudentLogin")
);

const Tests = lazy(() => import("./components/test-series/Tests/Tests"));
const Instructions = lazy(() =>
	import("./components/test-series/genericComponents/Instructions")
);
const ProfilePage = lazy(() =>
	import("./components/test-series/Profile/ProfilePage")
);

const TestNew = lazy(() =>
	import("./components/test-series/userGivingTest/TestNew")
);

const Result = lazy(() =>
	import("./components/test-series/ResultStudent/Result")
);
const ManageTests = lazy(() =>
	import("./components/test-series/Institute/ManageTests")
);
const AddQuestion = lazy(() =>
	import("./components/test-series/Institute/AddQuestion/AddQuestion")
);
const EditQuestion = lazy(() =>
	import("./components/test-series/Institute/EditQuestion")
);
const QuestionPaper = lazy(() =>
	import("./components/test-series/QuestionPaper")
);

const TestAnalysisPage = lazy(() =>
	import("./components/test-series/analysis/TestAnalysisPage")
);

const EnrollTeacher = lazy(() =>
	import("./components/test-series/enrollTeacher/EnrollTeacher")
);

const PrintQuestionPaperNew = lazy(() =>
	import("./components/test-series/Institute/PrintPaper/PrintQuestionPaperNew")
);

const ClassroomDetails = lazy(() =>
	import("./components/test-series/Institute/Classroom/ClassroomDetails")
);

const ClassroomStudents = lazy(() =>
	import("./components/test-series/Institute/Classroom/ClassroomStudents")
);

const ResultForAllStudents = lazy(() =>
	import("./components/test-series/Institute/Result/ResultForAllStudents")
);

const OtherStudentLogin = lazy(() =>
	import("./components/test-series/Auth/OtherStudentLogin")
);

const Register = lazy(() => import("./components/test-series/Auth/Register"));

const OverallResult = lazy(() =>
	import("./components/test-series/StudentResult/OverallResult")
);

const AllResultsForIndividualStudent = lazy(() =>
	import(
		"./components/test-series/Institute/Result/AllResultsForIndividualStudent"
	)
);

export const ROUTES = [
	{
		exact: true,
		path: "/",
		component: StudentLogin,
	},
	{
		exact: true,
		path: "/student-login",
		component: StudentLogin,
	},
	{
		exact: true,
		path: "/login",
		component: StudentLogin,
	},
	{
		exact: true,
		path: "/others-login",
		component: OtherStudentLogin,
	},
	{
		exact: true,
		path: "/register",
		component: Register,
	},
	{
		exact: true,
		path: "/test",
		component: Tests,
	},
	{
		exact: true,
		path: "/instructions/:testId/:testResultId/:encryptedTestName/:encryptedClassroomName/:encryptedTestType",
		component: Instructions,
	},
	{
		exact: true,
		path: "/student-profile",
		component: ProfilePage,
	},
	{
		exact: true,
		path: "/admin-profile",
		component: ProfilePage,
	},
	{
		exact: true,
		path: "/test/:testId/:testResultId/:encryptedTestName/:encryptedClassroomName/:encryptedTestType",
		//component: Test,
		component: TestNew,
	},
	{
		exact: true,
		path: "/result/:testId/:testResultId/:encryptedTestName/:encryptedClassroomName",
		component: Result,
	},
	{
		exact: true,
		path: "/manage-tests",
		component: ManageTests,
	},
	{
		exact: true,
		path: "/add-question-to-test/:testId/:encryptedTestName",
		component: AddQuestion,
	},
	{
		exact: true,
		path: "/edit-questions/:testId/:encryptedTestName",
		component: EditQuestion,
	},
	{
		exact: true,
		path: "/question-paper/:testId/:testResultId",
		component: QuestionPaper,
	},
	{
		exact: true,
		path: "/analysis/:testId/:testResultId/:questionIndex/:sectionIndexSelected/:encryptedTestName/:encryptedClassroomName",
		component: TestAnalysisPage,
	},
	{
		exact: true,
		path: "/enroll-student",
		component: EnrollForm,
	},
	{
		exact: true,
		path: "/enroll-teacher",
		component: EnrollTeacher,
	},
	{
		exact: true,
		path: "/print-question-paper/:testId/:encryptedStatus/:encryptedStartTime/:encryptedEndTime/:encryptedDuration",
		component: PrintQuestionPaperNew,
	},
	{
		exact: true,
		path: "/classroom",
		component: ClassroomDetails,
	},
	{
		exact: true,
		path: "/classroom-students/:classroomId/:encryptedClassroomName",
		component: ClassroomStudents,
	},
	{
		exact: true,
		path: "/result-all-students/:testId",
		component: ResultForAllStudents,
	},
	{
		exact: true,
		path: "/overall-result/:testId/:testResultId/:encryptedTestName/:encryptedClassroomName",
		component: OverallResult,
	},
	{
		exact: true,
		path: "/all-test-results/:userId/:encryptedUserName",
		component: AllResultsForIndividualStudent,
	},
	{
		exact: false,
		path: "/:notfound",
		component: NotFound,
	},
];
