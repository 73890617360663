export const checkAddTestDisable = ({
	testName,
	startDateTime,
	endDateTime,
	classroom,
	hours,
	minutes,
	helperTextForEndTime,
	helperTextForMinutes,
}) => {
	//console.log("classrooms",classroom);
	if (
		testName === "" ||
		startDateTime === "" ||
		endDateTime === "" ||
		//classroom === "" ||
		classroom.length === 0 ||
		classroom === undefined ||
		classroom === null ||
		hours === "" ||
		minutes === "" ||
		helperTextForEndTime !== "" ||
		helperTextForMinutes !== ""
	) {
		return true;
	}

	return false;
};

export const checkStudentDisable = ({
	name,
	rollNumber,
	contactNumber,
	whatsAppContact,
	classroom,
	password,
	gender,
	helperText,
}) => {
	if (
		name === "" ||
		rollNumber === "" ||
		contactNumber === "" ||
		whatsAppContact === "" ||
		// classrooms === "" ||
		classroom.length === 0 ||
		classroom === undefined ||
		classroom === null ||
		password === "" ||
		gender === "" ||
		helperText.name !== "" ||
		helperText.rollNumber !== "" ||
		helperText.contactNumber !== "" ||
		helperText.whatsAppContact !== "" ||
		helperText.gender !== "" ||
		helperText.emailId !== "" ||
		helperText.parentsContact !== "" ||
		helperText.password !== "" ||
		helperText.classroom !== ""
	) {
		return true;
	}
	return false;
};

export const checkTeacherDisable = ({
	name,
	contactNumber,
	whatsAppContact,
	classroom,
	password,
	gender,
	emailId,
	helperText,
}) => {
	if (
		name === "" ||
		contactNumber === "" ||
		whatsAppContact === "" ||
		classroom.length === 0 ||
		classroom === undefined ||
		classroom === null ||
		password === "" ||
		gender === "" ||
		emailId === "" ||
		helperText.name !== "" ||
		helperText.contactNumber !== "" ||
		helperText.whatsAppContact !== "" ||
		helperText.gender !== "" ||
		helperText.emailId !== "" ||
		helperText.password !== "" ||
		helperText.classroom !== ""
	) {
		return true;
	}
	return false;
};

export const checkStudentDisableWithoutContact = ({
	name,
	rollNumber,
	contactNumber,
	whatsAppContact,
	gender,
	emailId,
	classroom,
	password,
	helperText,
}) => {
	if (
		name === "" ||
		rollNumber === "" ||
		//contactNumber === "" ||
		whatsAppContact === "" ||
		gender === "" ||
		emailId === "" ||
		// classrooms === "" ||
		classroom.length === 0 ||
		classroom === undefined ||
		classroom === null ||
		password === "" ||
		helperText.name !== "" ||
		helperText.rollNumber !== "" ||
		//helperText.contactNumber !== "" ||
		helperText.whatsAppContact !== "" ||
		helperText.gender !== "" ||
		helperText.emailId !== "" ||
		helperText.parentsContact !== "" ||
		helperText.password !== "" ||
		helperText.classroom !== ""
	) {
		return true;
	}
	return false;
};

export const checkIncompleteStudentData = ({
	name,
	contactNumber,
	classroom,
	emailId,
	helperText,
}) => {
	if (
		name === "" ||
		contactNumber === "" ||
		emailId === "" ||
		classroom.length === 0 ||
		classroom === undefined ||
		classroom === null ||
		helperText.name !== "" ||
		helperText.emailId !== "" ||
		helperText.classroom !== "" ||
		helperText.contactNumber !== ""
	) {
		return true;
	}
	return false;
};
