import React from "react";

const OurFallbackComponent = ({
	error,
	componentStack,
	resetErrorBoundary,
}) => {
	return (
		<div
			className="container"
			style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
				minHeight: "100vh",
				padding: "10%",
			}}
		>
			<h1>An error occurred: {error.message}</h1>
			<button className="btn mainButton" onClick={resetErrorBoundary}>
				Try again
			</button>
			{typeof componentStack === "string" && <div>{componentStack}</div>}
		</div>
	);
};

export default OurFallbackComponent;
