import React, { Fragment, useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import store from "./store";
import axios from "axios";
import { SET_AUTH_TOKEN } from "./actions/type";
import "./style/style.css";
import "./App.css";
import { loader } from "./img/imageLinks";
import { ROUTES } from "./route";
import NoInternet from "./components/test-series/genericComponents/NoInternet"; //dont want lazy loaded
//import Navbar from "./components/layout/Navbar"; //Navbar is not lazy loaded
import Navbar from "./components/test-series/genericComponents/Navbar";
//const Footer = lazy(() => import("./components/layout/Footer"));

//export const AppURL = "https://pariksha-api.debugsbunny.com/eddytech"; //prod one

// export const AppURL = "http://localhost:8080/eddytech";
// export const AppURL = "http://135.181.27.5:8080/eddytech"; //qa
export const AppURL = "https://qadebugs.debugsbunny.com/eddytech"; //new Qa

function App(props) {
	axios.defaults.headers.post["Content-Type"] = "application/json";
	axios.defaults.baseURL = AppURL;

	const [onlineState, setOnlineState] = useState(true);

	//request interceptor to add the auth token header to requests
	axios.interceptors.request.use(
		(config) => {
			const accessToken = localStorage.getItem("accessToken");

			//sending institudeId as headers as api was designed in that way for register student.
			if (config.url !== "user/register/v1") {
				config.headers["instituteId"] = "0";
			}

			if (accessToken) {
				config.headers["Authorization"] = accessToken;

				if (config.url === "/admin/csv/upload") {
					config.headers["content-type"] = "multipart/form-data";
				}
			} else if (
				config.url !== "/user/login" ||
				config.url !== "/user/logout"
			) {
				//destroyToken();
			}
			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);

	//for renew of accessToken
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(err) => {
			return new Promise((resolve, reject) => {
				const originalReq = err.config;
				let refreshToken = localStorage.getItem("refreshToken");
				if (err.response.status !== 401) {
					return reject(err);
				} else if (
					err.response.status === 401 &&
					!originalReq._retry &&
					refreshToken
				) {
					originalReq._retry = true;

					let res = fetch(`${AppURL}/user/access-token/new`, {
						method: "POST",
						mode: "cors",
						cache: "no-cache",
						credentials: "same-origin",
						headers: {
							"Content-Type": "application/json",
							Device: "device",
							Authorization: localStorage.getItem("refreshToken"),
						},
						redirect: "follow",
						referrer: "no-referrer",
						body: {},
					})
						.then((res) => res.json())
						.then((res) => {
							//console.log("res axios", res);
							localStorage.setItem("accessToken", res?.accessToken);
							localStorage.setItem("refreshToken", res?.refreshToken);
							originalReq.headers["Authorization"] = res?.accessToken;
							originalReq.headers["Device"] = "device";

							return axios(originalReq);
						})
						.catch((error) => {
							// destroyToken();
							console.log(
								`Inside catch of renew access token err: ${err} and error:${error}`
							);
							console.log(error.response);
							console.table(error.response);
							// window.location.reload();
						});

					resolve(res);
				}
				return reject(err);
			});
		}
	);

	const destroyToken = () => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
	};

	useEffect(() => {
		window.addEventListener("offline", () => {
			setOnlineState(false);
		});

		window.addEventListener("online", () => {
			setOnlineState(true);
		});
	}, []);

	useEffect(() => {
		if (localStorage.accessToken) {
			store.dispatch({
				type: SET_AUTH_TOKEN,
				payload: {
					accessToken: localStorage.accessToken,
					refreshToken: localStorage.refreshToken,
					role: localStorage.role,
				},
			});
		}
	});

	const getFallbackUI = () => {
		return (
			<div className="fallBack-UI container">
				<img className="loaderWrapper" src={loader} alt="loading..." />
			</div>
		);
	};

	return (
		<BrowserRouter>
			<Fragment>
				{/* window.location.pathname !== "/" &&
					window.location.pathname !== "/login" &&
					window.location.pathname !== "/student-login" &&
	window.location.pathname !== "/institute-login" && <Navbar /> */}
				<Navbar />
				<button
					style={{ display: "none" }}
					data-toggle="modal"
					data-target="#userDetailsModal"
					id="userDetailsBtn"
				/>
				{onlineState ? (
					<Suspense fallback={getFallbackUI()}>
						<Switch>
							<Route
								exact
								path="/"
								render={() => {
									return <Redirect to="/login" />;
								}}
							/>
							{ROUTES.map((route, index) => (
								<Route
									key={route.path}
									exact={route.exact}
									path={route.path}
									component={route.component}
								/>
							))}
						</Switch>
					</Suspense>
				) : (
					<NoInternet />
				)}
				{/*  <Suspense fallback={<div></div>}>
          <Footer />
        </Suspense> */}
			</Fragment>
		</BrowserRouter>
	);
}

export default App;
