import newlogo from "./newLogo.svg";
import hamburger from "./hamburger.svg";
import Certified from "./Certified.svg";
import certifiedMedal from "./certified-medal.svg";
import chevronOpen from "./chevronOpen.svg";
import chevronClose from "./chevronClose.svg";
import bannerImage from "./banner-img.jpg";
import uploadSmallIcon from "./upload-small-icn.svg";
import emptyAllCourses from "./emptyAllCourses.svg";
import emptyMyCourses from "./emptyMyCourses.svg";
import wavingHand from "./wavingHand.svg";
import noInternet from "./noInternet.svg";
import thankyoulogo from "./thank-you-logo.svg";
import forwardArrow from "./forwardArrow.svg";
import backwardArrow from "./backwardArrow.svg";
import AppNameImage from "./app-name-img.svg";
import previous from "./previous.svg";
import next from "./next.svg";
import Acharya from "./Acharya_logo.svg";

// import pgLogoSvg from "./parikshagruhLogo.svg";

// import loginIllustration from "./loginIllustration.svg";
import pgLogoSvg from "./pg1.svg";

import loginIllustration from "./pgIllustration2.svg";
import waveTop from "./topWave.svg";
import waveBottom from "./bottomWave.svg";

import parikshagruhgif from "./parikshagruhgif.gif";
import loginDots from "./loginDots.svg";

import searchIcon from "./searchIcon.svg";

import result from "./result.svg";
import trophy from "./trophy.svg";
import medal from "./medal.svg";
import percentage from "./percentage.svg";

import checked from "./checked.svg";
import clock from "./clock.svg";
import clock1 from "./clock1.svg";
import dartboard from "./dartboard.svg";
import deleteImage from "./delete.svg";
import time from "./time.svg";
import sillyMistakes from "./sillyMistakes.png";
import wastedAttemptsInHaste from "./wastedAttemptsInHaste.png";
import medium from "./medium.png";
import strong from "./strong.png";
import weakness from "./weakness.png";

export const AcharyaLogo = Acharya;
export const previousIcon = previous;
export const nextIcon = next;
export const newLogo = newlogo;
export const AppNameImg = AppNameImage;
export const uploadSmallIcn = uploadSmallIcon;
export const bannerImg = bannerImage;
export const hamburgerIcon = hamburger;
export const certifiedIcon = Certified;
export const certifiedMedalIcon = certifiedMedal;
export const chevronCloseIcon = chevronClose;
export const chevronOpenIcon = chevronOpen;
export const emptyMyCoursesIcon = emptyMyCourses;
export const emptyAllCoursesIcon = emptyAllCourses;
export const forwardArrowIcon = forwardArrow;
export const backwardArrowIcon = backwardArrow;
export const wavingHandIcon = wavingHand;
export const noInternetImage = noInternet;
export const thankYouLogo = thankyoulogo;

export const testimonialImgVedant =
	"http://assets.debugsbunny.com/testimonials/review_1.svg";
export const testimonialImgAditya =
	"http://assets.debugsbunny.com/testimonials/review_2.svg";
export const testimonialImgSamved =
	"http://assets.debugsbunny.com/testimonials/review_3.svg";

export const dashboardBunnyNoLecture =
	"http://assets.debugsbunny.com/images/debugsbunny/dashboardBunnyNoLecture.svg";

export const dashboardIcon =
	"http://assets.debugsbunny.com/images/debugsbunny/dashboard.svg";

export const referAFriendIcon =
	"http://assets.debugsbunny.com/images/debugsbunny/referAFriend.svg";

// export const loader =
// 	"http://assets.debugsbunny.com/images/debugsbunny/loader.gif";

export const loader = parikshagruhgif;

export const cardImage =
	"http://assets.debugsbunny.com/images/debugsbunny/cardImage.svg";

export const playBtn =
	"http://assets.debugsbunny.com/images/debugsbunny/play-button.svg";

export const fullStarIcon =
	"http://assets.debugsbunny.com/images/debugsbunny/fullStar.svg";

export const halfStarIcon =
	"http://assets.debugsbunny.com/images/debugsbunny/halfStar.svg";

export const twoFingerIcon =
	"http://assets.debugsbunny.com/images/debugsbunny/two-finger-image.svg";

export const parikshagruhLogo = pgLogoSvg;

export const loginIllustrationImage = loginIllustration;
export const waveTopImage = waveTop;
export const waveBottomImage = waveBottom;
export const loginDotsImage = loginDots;

export const searchIconPG = searchIcon;

export const resultSvg = result;
export const trophySvg = trophy;
export const medalSvg = medal;
export const percentageSvg = percentage;

export const checkedSvg = checked;
export const clockSvg = clock;
export const clock1Svg = clock1;
export const dartboardSvg = dartboard;
export const deleteSvg = deleteImage;
export const timeSvg = time;
export const sillyMistakesImg = sillyMistakes;
export const wastedAttemptsInHasteImg = wastedAttemptsInHaste;
export const strongImg = strong;
export const mediumImg = medium;
export const weaknessImg = weakness;
