import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: "100%",
		paddingRight: "0.5rem",
	},
	overRideSelect: {
		whiteSpace: "break-spaces",
		textAlign: "left",
		"&::before": {
			whiteSpace: "break-spaces",
			textAlign: "left",
		},
		"&::after": {
			whiteSpace: "break-spaces",
			textAlign: "left",
		},
		"& div": {
			whiteSpace: "break-spaces",
			textAlign: "left",
		},
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const ClassroomField = (props) => {
	const {
		classrooms,
		validateClassroom,
		changeClassroom,
		classroom,
		helperTextClassroom,
		disabled,
	} = props;

	const classes = useStyles();

	return (
		<div>
			<FormControl
				required
				className={classes.formControl}
				error={helperTextClassroom ? true : false}
			>
				<InputLabel id="demo-mutiple-checkbox-label">Classrooms</InputLabel>
				<Select
					labelId="demo-mutiple-checkbox-label"
					id="demo-mutiple-checkbox"
					multiple
					value={classroom}
					onChange={(event) => changeClassroom(event)}
					input={<Input />}
					renderValue={(selected) => selected.join(", ")}
					MenuProps={MenuProps}
					onBlur={() => validateClassroom()}
					className={classes.overRideSelect}
					disabled={disabled}
				>
					{classrooms &&
						classrooms.length > 0 &&
						classrooms.map((option) => (
							<MenuItem key={option.id} value={option.name}>
								<Checkbox checked={classroom.indexOf(option.name) > -1} />
								<ListItemText primary={option.name} />
							</MenuItem>
						))}
				</Select>
				<FormHelperText>{helperTextClassroom}</FormHelperText>
			</FormControl>
		</div>
	);
};

export default ClassroomField;
