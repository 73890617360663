import {
	GET_TEST_FROM_SEARCH_TEXT,
	GET_TEST_FROM_SEARCH_TEXT_SUCCESS,
	GET_TEST_FROM_SEARCH_TEXT_FAILURE,
	ERASE_SEARCH_ERROR,
	GET_INCOMPLETE_STUDENT_DATA,
	GET_INCOMPLETE_STUDENT_DATA_SUCCESS,
	GET_INCOMPLETE_STUDENT_DATA_FAILURE,
	SEND_COMPLETE_STUDENT_DATA,
	SEND_COMPLETE_STUDENT_DATA_SUCCESS,
	SEND_COMPLETE_STUDENT_DATA_FAILURE,
} from "../actions/type";

const initialState = {
	loading: false,
	error: "",
	data: {},
	message: "",
	incompleteStudentData: {},
};

const studentMiscReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_TEST_FROM_SEARCH_TEXT:
			return {
				...state,
				loading: true,
				error: "",
				data: {},
			};

		case GET_TEST_FROM_SEARCH_TEXT_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};

		case GET_TEST_FROM_SEARCH_TEXT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case ERASE_SEARCH_ERROR:
			return {
				...state,
				error: "",
			};
		case GET_INCOMPLETE_STUDENT_DATA:
			return {
				...state,
				loading: true,
				incompleteStudentData: {},
			};
		case GET_INCOMPLETE_STUDENT_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				incompleteStudentData: payload,
			};
		case GET_INCOMPLETE_STUDENT_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case SEND_COMPLETE_STUDENT_DATA:
			return {
				...state,
				loading: true,
			};
		case SEND_COMPLETE_STUDENT_DATA_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case SEND_COMPLETE_STUDENT_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		default:
			return state;
	}
};

export default studentMiscReducer;
