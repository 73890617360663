import axios from "axios";
import { get } from "lodash";

import {
	REQ_LOGIN_,
	LOGIN_SUCCESS_,
	LOGIN_FAILED_,
	LOGOUT,
	LOGOUT_SUCCESS,
	LOGOUT_FAILURE,
	GET_ACCESSTOKEN,
	GET_ACCESSTOKEN_SUCCESS,
	GET_ACCESSTOKEN_FAILED,
	SOCIAL_LOGIN,
	SOCIAL_LOGIN_SUCCESS,
	SOCIAL_LOGIN_FAILURE,
	GET_CLASSROOMS_NON_AUTHENTICATED,
	GET_CLASSROOMS_NON_AUTHENTICATED_SUCCESS,
	GET_CLASSROOMS_NON_AUTHENTICATED_FAILURE,
	REGISTER_STUDENT,
	REGISTER_STUDENT_SUCCESS,
	REGISTER_STUDENT_FAILURE,
	TRIGGER_OTP,
	TRIGGER_OTP_SUCCESS,
	TRIGGER_OTP_FAILURE,
	VALIDATE_OTP,
	VALIDATE_OTP_SUCCESS,
	VALIDATE_OTP_FAILURE,
	ERASE_AUTHENTICATION_ERROR,
} from "./type";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
// };
// const appUrl = "http://95.216.174.93:8080/eddytech";

export const login = (payload) => async (dispatch) => {
	//console.log("inside login")
	dispatch({
		type: REQ_LOGIN_,
	});
	try {
		const res = await axios.post("/user/login", payload);
		//console.log(`res `, res.data);
		//axios.defaults.headers["Authorization"] = res.data.token;
		dispatch({
			type: LOGIN_SUCCESS_,
			payload: res.data.data,
		});
	} catch (err) {
		// //console.log("inside", err?.response?.data?.errorMessage);
		// const errors = get(err, "err?.response?.data?.errorMessage");
		// //console.log("errors", errors);
		const errors = err?.response?.data?.errorMessage;
		dispatch({
			type: LOGIN_FAILED_,
			payload: errors,
		});
	}
};

export const logout = () => async (dispatch) => {
	dispatch({ type: LOGOUT });

	try {
		const res = await axios.post("/user/logout");
		dispatch({
			type: LOGOUT_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: LOGOUT_FAILURE,
			payload: errors,
		});
	}
};

//HERE INSTEAD OF ACCESS TOKEN, NEED TO SEND REFRESH TOKEN i.e API CALL IS LITTLE DIFFRENT
export const getAccessToken = () => async (dispatch) => {
	const refreshToken = localStorage.getItem("refreshToken");
	if (!refreshToken) return;
	dispatch({ type: GET_ACCESSTOKEN });

	try {
		const res = await axios.post(
			"/user/access-token/new",
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: refreshToken,
				},
			}
		);
		dispatch({
			type: GET_ACCESSTOKEN_SUCCESS,
			payload: res.data,
		});
		//console.log("checking res", res);
		return res.status;
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: GET_ACCESSTOKEN_FAILED,
			payload: errors,
		});
	}
};

export const socialLogin = (payload) => async (dispatch) => {
	//console.log("inside login")
	dispatch({
		type: SOCIAL_LOGIN,
	});
	try {
		const res = await axios.post("/user/social/login/google", payload);
		dispatch({
			type: SOCIAL_LOGIN_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const errors = err?.response?.data?.errorMessage;
		dispatch({
			type: SOCIAL_LOGIN_FAILURE,
			payload: errors,
		});
	}
};

export const getClassroomForRegisterPage = () => async (dispatch) => {
	dispatch({
		type: GET_CLASSROOMS_NON_AUTHENTICATED,
	});

	try {
		const res = await axios.get("/v1/meta/classroom/0");
		dispatch({
			type: GET_CLASSROOMS_NON_AUTHENTICATED_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: GET_CLASSROOMS_NON_AUTHENTICATED_FAILURE,
			payload: error,
		});
	}
};

// const value = [
// 	{
// 		id: 2,
// 		name: "10th CBSE and NTSE/Competitive Exams",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 14,
// 		name: "Repeaters Batch",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 20,
// 		name: "ASAT Practice",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 24,
// 		name: "Home tution",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 25,
// 		name: "Updated classroom",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 26,
// 		name: "ZTest",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 38,
// 		name: "Classroom new12345",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 39,
// 		name: "Classroom 123789",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 40,
// 		name: "New class 456",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 41,
// 		name: "new classroom",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 43,
// 		name: "Class 29 July",
// 		instituteId: 1,
// 	},
// 	{
// 		id: 44,
// 		name: "class 30 july",
// 		instituteId: 1,
// 	},
// ];

export const registerStudent = (payload) => async (dispatch) => {
	dispatch({
		type: REGISTER_STUDENT,
	});

	try {
		const res = await axios.post("/user/register/v1", payload);

		dispatch({
			type: REGISTER_STUDENT_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const errors = err?.response?.data?.errorMessage;
		dispatch({
			type: REGISTER_STUDENT_FAILURE,
			payload: errors,
		});
	}
};

export const triggerOtp = (payload) => async (dispatch) => {
	dispatch({
		type: TRIGGER_OTP,
	});

	try {
		const res = await axios.post("/user/triggerOtp", payload);

		dispatch({
			type: TRIGGER_OTP_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: TRIGGER_OTP_FAILURE,
			payload: error,
		});
	}
};

export const validateOtpApi = (payload) => async (dispatch) => {
	dispatch({
		type: VALIDATE_OTP,
	});

	try {
		const res = await axios.post("/user/validateOtp", payload);

		dispatch({
			type: VALIDATE_OTP_SUCCESS,
			payload: res.data.data,
		});

		return true;
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: VALIDATE_OTP_FAILURE,
			payload: error,
		});

		setTimeout(() => {
			dispatch({
				type: ERASE_AUTHENTICATION_ERROR,
			});
		}, 4000);

		return false;
	}
};

export const eraseAuthenticationError = () => (dispatch) => {
	dispatch({
		type: ERASE_AUTHENTICATION_ERROR,
	});
};
