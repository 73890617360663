import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		position: "absolute",
		margin: "2rem auto",
		display: "flex",
		justifyContent: "center",
	},
}));

const AlertComponent = (props) => {
	const { message, alertType } = props; // alert type should be error , warning, info , success
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);

	//console.log("errorINside", error);
	//console.log(`message`, message);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={handleClose} severity={alertType}>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default AlertComponent;

// <div className={classes.root} >
// 				<Alert onClose={handleClose} severity="error" open={open} autoHideDuration={6000}>
// 					{error}
// 				</Alert>
// 		</div>
