export const numSuffixOf = (i) => {
	let j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + "st";
	}
	if (j === 2 && k !== 12) {
		return i + "nd";
	}
	if (j === 3 && k !== 13) {
		return i + "rd";
	}
	return i + "th";
};

export function windowOpen(url) {
	if (!url.match(/^https?:\/\//i)) {
		url = "http://" + url;
	}
	return window.open(url, "_blank");
}

export function isUrlValid(userInput) {
	let res = userInput.match(
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
	);
	if (res === null) return false;
	else return true;
}

//returns
// getHrsMinSecfromSeconds(1800);
// "30 Min "
// getHrsMinSecfromSeconds(7200);
// "2 Hrs "
// getHrsMinSecfromSeconds(5400);
// "1 Hr 30 Min "
// getHrsMinSecfromSeconds(5410);
// "1 Hr 30 Min 10 Sec"
export const getHrsMinSecfromSeconds = (seconds) => {
	//return new Date(seconds * 1000).toISOString().substr(11, 8);

	seconds = Number(seconds);
	let h = Math.floor(seconds / 3600);
	let m = Math.floor((seconds % 3600) / 60);
	let s = Math.floor((seconds % 3600) % 60);

	let hDisplay = h > 0 ? h + (h === 1 ? " Hr " : " Hrs ") : "";
	let mDisplay = m > 0 ? m + (m === 1 ? " Min " : " Min ") : "";
	let sDisplay = s > 0 ? s + (s === 1 ? " Sec" : " Sec") : "";
	return hDisplay + mDisplay + sDisplay;
};

//a regex to verify email id and return true or false
export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const encrypt = (text) => {
	if (!text) return;
	const salt = "salt";
	const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
	const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = (code) =>
		textToChars(salt).reduce((a, b) => a ^ b, code);

	return text
		.split("")
		.map(textToChars)
		.map(applySaltToChar)
		.map(byteHex)
		.join("");
};

export const decrypt = (encoded) => {
	if (!encoded) return;
	const salt = "salt";
	const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
	const applySaltToChar = (code) =>
		textToChars(salt).reduce((a, b) => a ^ b, code);

	return encoded
		.match(/.{1,2}/g)
		.map((hex) => parseInt(hex, 16))
		.map(applySaltToChar)
		.map((charCode) => String.fromCharCode(charCode))
		.join("");
};

//function to check whether string contains a number or not and returns true or false.
export const hasNumber = (myString) => {
	return /\d/.test(myString);
};

//get the number max upto decimal points
//e.g.
// console.log( toFixedIfNecessary( 1.999, 2 ));    // 2
// console.log( toFixedIfNecessary( 2, 2 ));        // 2
// console.log( toFixedIfNecessary( 2.1, 2 ));      // 2.1
// console.log( toFixedIfNecessary( 2.05, 2 ));     // 2.05
// console.log( toFixedIfNecessary( 2.05342, 2 ));  // 2.05
// console.log( toFixedIfNecessary( 2.04999, 2 ));  // 2.05
export const getMaxDigitsRequiredFromANumber = (value, dp) => {
	if (!value || typeof value !== "number") return "";

	if (typeof dp !== "undefined" && dp !== null) dp = 2;

	return +parseFloat(value).toFixed(dp);
};

//return 5400 --> [1, 30];
export const getHrsMinSecfromSecondsAsAnArray = (seconds) => {
	//return new Date(seconds * 1000).toISOString().substr(11, 8);

	seconds = Number(seconds);
	let h = Math.floor(seconds / 3600);
	let m = Math.floor((seconds % 3600) / 60);
	let s = Math.floor((seconds % 3600) % 60);

	let hDisplay = h > 0 ? h : 0;
	let mDisplay = m > 0 ? m : 0;
	let sDisplay = s > 0 ? s : 0;
	return [hDisplay, mDisplay, sDisplay];
};

//Parikshagruh one for deployment
export const clientId =
	"1097866282592-qdvrh2kh18rlgpaep1vhiqpj1n1mqsfc.apps.googleusercontent.com";

export const bottomTextForShowingResultOfAllStudentsInAtTest = (
	instituteName
) => {
	return `Note: This sheet is meant for internal circualtion in ${instituteName} only. Not to be shared on your personal social media handles`;
};

export const getUserName = () => {
	const user = localStorage.getItem("userName") || "";

	if (user.length > 0) return `Hello ${user},`;
	return "";
};

export const onlyUserName = () => {
	const user = localStorage.getItem("userName") || "";

	if (user.length > 0) return user;
	return "";
};
