import { combineReducers } from "redux";
import authenticationReducer from "./authenticationReducer";
import testReducer from "./testReducer";
import studentReducer from "./studentReducer";
import teacherReducer from "./teacherReducer";
import addQuestionReducer from "./addQuestionReducer";
import classroomReducer from "./classroomReducer";

import adminMiscReducer from "./adminMiscReducer";
import studentMissc from "./studentMissc";
import studentResultReducer from "./studentResultReducer";

const allReducers = combineReducers({
	authentication: authenticationReducer,
	test: testReducer,
	student: studentReducer,
	teacher: teacherReducer,
	addQuestion: addQuestionReducer,
	classroom: classroomReducer,
	adminMisc: adminMiscReducer,
	studentMisc: studentMissc,
	studentResult: studentResultReducer,
});

const rootReducer = (state, action) => {
	//if user logs out, clearing the redux store
	if (action.type === "LOGOUT_SUCCESS" || action.type === "LOGOUT_FAILURE") {
		state = undefined;
	}

	return allReducers(state, action);
};

export default rootReducer;
