import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	CardActions,
	TextField,
	Button,
	Grid,
	Typography,
} from "@material-ui/core";
import ClassroomField from "../Institute/AddTest/ClassroomField";
import { useStyles } from "../Institute/InstituteStyles";
import { enrollStudent, getClassrooms } from "../../../actions/test";
import { checkStudentDisable } from "../Institute/AddTest/commonFunctions";
import EnrollmentStatus from "./enrollmentStatus";
import AlertComponent from "../genericComponents/AlertComponent";

import { validateEmail, hasNumber } from "./../../../utils/commonUtils";

const EnrollForm = ({
	classrooms,
	getClassrooms,
	enrollStudent,
	enrolledFlag,
	enrolledStatus,
}) => {
	const classes = useStyles();
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [rollNumber, setRollNumber] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [whatsAppContact, setWhatsAppContact] = useState("");
	const [parentsContact, setParentsContact] = useState("");
	const [classroom, setClassroom] = useState([]);
	const [classroomIds, setClassroomIds] = useState([]);
	const [password, setPassword] = useState("");
	const [emailId, setEmailId] = useState("");
	const [helperText, setHelperText] = useState({
		name: "",
		gender: "",
		rollNumber: "",
		contactNumber: "",
		whatsAppContact: "",
		parentsContact: "",
		classroom: "",
		password: "",
		emailId: "",
	});

	useEffect(() => {
		getClassrooms();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (name.length > 0) {
			validateStudentName();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]);

	useEffect(() => {
		if (rollNumber.length > 0) {
			validateRollNumber();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rollNumber]);

	useEffect(() => {
		if (contactNumber.length > 0) {
			validateContactNumber();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactNumber]);

	useEffect(() => {
		if (whatsAppContact.length > 0) {
			validateWhatsAppContact();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whatsAppContact]);

	useEffect(() => {
		if (gender.length > 0) {
			validateGender();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gender]);

	useEffect(() => {
		if (emailId.length > 0) {
			validateEmailId();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailId]);

	useEffect(() => {
		if (parentsContact.length > 0) {
			validateParentsContact();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentsContact]);

	useEffect(() => {
		if (password.length > 0) {
			validatePassword();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [password]);

	useEffect(() => {
		if (classroom.length > 0) {
			validateClassroom();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classroom]);

	const validateStudentName = () => {
		if (name === "" || name.trim() === "") {
			setHelperText((prev) => {
				return {
					...prev,
					name: "Student Name is Mandatory",
				};
			});
		} else if (hasNumber(name)) {
			setHelperText((prev) => {
				return {
					...prev,
					name: "Student Name shouldn't contain numbers",
				};
			});
		} else if (name !== "") {
			setHelperText((prev) => {
				return {
					...prev,
					name: "",
				};
			});
		}
	};

	const validateRollNumber = () => {
		if (rollNumber === "" || rollNumber.trim() === "") {
			setHelperText((prev) => {
				return {
					...prev,
					rollNumber: "Roll Number is Mandatory",
				};
			});
		} else if (rollNumber !== "") {
			setHelperText((prev) => {
				return {
					...prev,
					rollNumber: "",
				};
			});
		}
	};

	const validateContactNumber = () => {
		if (contactNumber === "" || contactNumber.trim() === "") {
			setHelperText((prev) => {
				return {
					...prev,
					contactNumber: "Contact Number is Mandatory",
				};
			});
		} else if (
			contactNumber[0] === "0" ||
			contactNumber.substring(0, 3) === "+91"
		) {
			setHelperText((prev) => {
				return {
					...prev,
					contactNumber: "Contact number should not start with 0 or +91",
				};
			});
		} else if (contactNumber.length !== 10) {
			setHelperText((prev) => {
				return {
					...prev,
					contactNumber: "Contact number must be of 10 digits",
				};
			});
		} else if (contactNumber !== "") {
			setHelperText((prev) => {
				return {
					...prev,
					contactNumber: "",
				};
			});
		}
	};

	const validateWhatsAppContact = () => {
		if (whatsAppContact === "" || whatsAppContact.trim() === "") {
			setHelperText((prev) => {
				return {
					...prev,
					whatsAppContact: "WhatsApp Number is Mandatory",
				};
			});
		} else if (
			whatsAppContact[0] === "0" ||
			whatsAppContact.substring(0, 3) === "+91"
		) {
			setHelperText((prev) => {
				return {
					...prev,
					whatsAppContact: "WhatsApp number should not start with 0 or +91",
				};
			});
		} else if (whatsAppContact.length !== 10) {
			setHelperText((prev) => {
				return {
					...prev,
					whatsAppContact: "WhatsApp number must be of 10 digits",
				};
			});
		} else if (whatsAppContact !== "") {
			setHelperText((prev) => {
				return {
					...prev,
					whatsAppContact: "",
				};
			});
		}
	};

	const validateGender = () => {
		if (gender.length === 0) {
			setHelperText((prev) => {
				return {
					...prev,
					gender: "Choose a Gender",
				};
			});
		} else {
			setHelperText((prev) => {
				return {
					...prev,
					gender: "",
				};
			});
		}
	};

	const validateEmailId = () => {
		if (emailId.length === 0 || emailId.trim().length === 0) {
			setHelperText((prev) => {
				return {
					...prev,
					emailId: "Email Id is mandatory",
				};
			});
		} else if (emailId.trim().length > 0 && !validateEmail(emailId)) {
			setHelperText((prev) => {
				return {
					...prev,
					emailId: "Email Id is invalid",
				};
			});
		} else {
			setHelperText((prev) => {
				return {
					...prev,
					emailId: "",
				};
			});
		}
	};

	const validateParentsContact = () => {
		if (parentsContact[0] === "0" || parentsContact.substring(0, 3) === "+91") {
			setHelperText((prev) => {
				return {
					...prev,
					parentsContact:
						"Parent Contact number should not start with 0 or +91",
				};
			});
		} else if (
			parentsContact.trim().length > 0 &&
			parentsContact.length !== 10
		) {
			setHelperText((prev) => {
				return {
					...prev,
					parentsContact: "Contact number must be of 10 digits",
				};
			});
		} else {
			setHelperText((prev) => {
				return {
					...prev,
					parentsContact: "",
				};
			});
		}
	};

	const validatePassword = () => {
		if (password === "" || password.trim() === "") {
			setHelperText((prev) => {
				return {
					...prev,
					password: "Password is Mandatory",
				};
			});
		} else if (password !== "") {
			setHelperText((prev) => {
				return {
					...prev,
					password: "",
				};
			});
		}
	};

	const validateClassroom = () => {
		if (classroom.length === 0) {
			setHelperText((prev) => {
				return {
					...prev,
					classroom: "Choose a classroom",
				};
			});
		} else {
			setHelperText((prev) => {
				return {
					...prev,
					classroom: "",
				};
			});
		}
	};

	const enroll = () => {
		let payload = {
			name: name,
			gender: gender,
			rollNumber: rollNumber,
			contactNumber: contactNumber,
			whatsAppContact: whatsAppContact,
			parentsContact: parentsContact,
			classRoomIds: classroomIds,
			password: password,
			emailId: emailId,
		};
		enrollStudent(payload);
	};

	const changeClassroom = (event) => {
		let classroomSelected = event.target.value;
		let prevIds = [];
		classroomSelected.forEach((element) => {
			let ind = classrooms.findIndex((el) => {
				return el.name === element;
			});
			prevIds.push(classrooms[ind].id);
			setClassroomIds(prevIds);
		});
		setClassroom(classroomSelected);
	};

	if (enrolledFlag && enrolledStatus !== "") {
		return <EnrollmentStatus enrolledStatus={enrolledStatus} />;
	} else {
		return (
			<div className={classes.formBody}>
				{!enrolledFlag && enrolledStatus && (
					<AlertComponent message={enrolledStatus} alertType="error" />
				)}
				<form style={{ width: "100%" }}>
					<div className={classes.root}>
						<Typography variant="h4" gutterBottom={true}>
							Enroll Student
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<TextField
									required
									id="studentName"
									label="Student Name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.name ? true : false}
									helperText={helperText.name}
									onBlur={() => validateStudentName()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									id="rollNo"
									label="Roll No."
									type="number"
									value={rollNumber}
									onChange={(e) => setRollNumber(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.rollNumber ? true : false}
									helperText={helperText.rollNumber}
									onBlur={() => validateRollNumber()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									id="contact"
									label="Contact No."
									type="number"
									value={contactNumber}
									onChange={(e) => setContactNumber(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.contactNumber ? true : false}
									helperText={helperText.contactNumber}
									onBlur={() => validateContactNumber()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									id="whatsapp"
									label="WhatsApp No."
									type="number"
									value={whatsAppContact}
									onChange={(e) => setWhatsAppContact(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									// InputLabelProps={{
									// 	shrink: true,
									// }}
									error={helperText.whatsAppContact ? true : false}
									helperText={helperText.whatsAppContact}
									onBlur={() => validateWhatsAppContact()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									select
									id="gender"
									label="Gender"
									value={gender}
									onChange={(e) => setGender(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.gender ? true : false}
									helperText={helperText.gender}
									onBlur={() => validateGender()}
								>
									<option key={1} value={"MALE"}>
										Male
									</option>
									<option key={2} value={"FEMALE"}>
										Female
									</option>
								</TextField>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									id="email"
									label="Email Id"
									type="email"
									value={emailId}
									onChange={(e) => setEmailId(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.emailId ? true : false}
									helperText={helperText.emailId}
									onBlur={() => validateEmailId()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="parentNo"
									label="Parent Contact No."
									type="number"
									value={parentsContact}
									onChange={(e) => setParentsContact(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.parentsContact ? true : false}
									helperText={helperText.parentsContact}
									onBlur={() => validateParentsContact()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									id="password"
									label="Password"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									margin="dense"
									variant="outlined"
									fullWidth
									error={helperText.password ? true : false}
									helperText={helperText.password}
									onBlur={() => validatePassword()}
									inputProps={{
										autoComplete: "new-password",
										form: {
											autoComplete: "off",
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} style={{ margin: "auto" }}>
								<ClassroomField
									classrooms={classrooms}
									validateClassroom={validateClassroom}
									changeClassroom={changeClassroom}
									classroom={classroom}
									helperTextClassroom={helperText.classroom}
								/>
							</Grid>
						</Grid>
						<CardActions className={classes.actions}>
							<Button
								type="button"
								color="primary"
								onClick={() => enroll()}
								disabled={checkStudentDisable({
									name,
									rollNumber,
									contactNumber,
									whatsAppContact,
									classroom,
									password,
									gender,
									helperText,
								})}
							>
								SUBMIT
							</Button>
						</CardActions>
					</div>
				</form>
			</div>
		);
	}
};

const mapStateToProps = (state) => ({
	classrooms: state.test.classrooms,
	enrolledFlag: state.test.enrolledFlag,
	enrolledStatus: state.test.enrolledStatus,
});

const mapDispatchToProps = {
	getClassrooms,
	enrollStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrollForm);
