import axios from "axios";
import { get } from "lodash";
import {
	GET_TEST_QUESTIONS,
	GET_TEST_QUESTIONS_FAILED,
	GET_TEST_QUESTIONS_SUCCESS,
	GET_INPROGRESS_TESTS,
	GET_INPROGRESS_TESTS_SUCCESS,
	GET_INPROGRESS_TESTS_FAILED,
	GET_COMPLETED_TESTS,
	GET_COMPLETED_TESTS_SUCCESS,
	GET_COMPLETED_TESTS_FAILED,
	GET_NOT_STARTED_TESTS,
	GET_NOT_STARTED_TESTS_SUCCESS,
	GET_QUESTIONS,
	GET_QUESTIONS_FAILED,
	GET_QUESTIONS_SUCCESS,
	GET_TEST_RESULT,
	GET_TEST_RESULT_SUCCESS,
	GET_TEST_RESULT_FAILED,
	GET_TEST_RESULT_ANS_RESPONSE,
	GET_TEST_RESULT_ANS_RESPONSE_SUCCESS,
	GET_TEST_RESULT_ANS_RESPONSE_FAILED,
	GET_QUESTION_ANALYSIS,
	GET_QUESTION_ANALYSIS_SUCCESS,
	GET_QUESTION_ANALYSIS_FAILED,
	GET_SUBJECTS,
	GET_SUBJECTS_SUCCESS,
	GET_SUBJECTS_FAILED,
	SAVE_ANS_RESPONSE,
	SAVE_ANS_RESPONSE_SUCCESS,
	SAVE_ANS_RESPONSE_FAILED,
	SAVE_ANS,
	SAVE_ANS_SUCCESS,
	SAVE_ANS_FAILED,
	SUBMIT_TEST,
	SUBMIT_TEST_SUCCESS,
	SUBMIT_TEST_FAILED,
	UPDATE_TEST_STATUS,
	UPDATE_TEST_STATUS_SUCCESS,
	UPDATE_TEST_STATUS_FAILED,
	GET_TEST_LIST,
	GET_TEST_LIST_SUCCESS,
	GET_TEST_LIST_FAILED,
	GET_CLASSROOM,
	GET_CLASSROOM_SUCCESS,
	GET_CLASSROOM_FAILED,
	GET_TOTAL_TEST_COUNT,
	GET_TOTAL_TEST_COUNT_SUCCESS,
	GET_TOTAL_TEST_COUNT_FAILED,
	ADD_NEW_TEST,
	ADD_NEW_TEST_SUCCESS,
	ADD_NEW_TEST_FAILED,
	DELETE_QUESTION,
	DELETE_QUESTION_SUCCESS,
	DELETE_QUESTION_FAILURE,
	QUESTION_META_DATA,
	QUESTION_META_DATA_SUCCESS,
	QUESTION_META_DATA_FAILURE,
	SUBJECT_META_DATA,
	SUBJECT_META_DATA_SUCCESS,
	SUBJECT_META_DATA_FAILURE,
	CHAPTER_META_DATA,
	CHAPTER_META_DATA_SUCCESS,
	CHAPTER_META_DATA_FAILURE,
	TOPIC_META_DATA,
	TOPIC_META_DATA_SUCCESS,
	TOPIC_META_DATA_FAILURE,
	GET_QUESTIONS_TO_SET,
	GET_QUESTIONS_TO_SET_SUCCESS,
	GET_QUESTIONS_TO_SET_FAILURE,
	ENROLL_STUDENT,
	ENROLL_STUDENT_SUCCESS,
	ENROLL_STUDENT_FAILURE,
	GET_QUESTION_COUNT,
	GET_QUESTION_COUNT_SUCCESS,
	GET_QUESTION_COUNT_FAILURE,
	SEND_SELECTED_QUESTION,
	SEND_SELECTED_QUESTION_SUCCESS,
	SEND_SELECTED_QUESTION_FAILURE,
	TOTAL_TEST_COUNT_BY_USER,
	TOTAL_TEST_COUNT_BY_USER_SUCCESS,
	TOTAL_TEST_COUNT_BY_USER_FAILURE,
	ADD_CLASSROOM,
	ADD_CLASSROOM_SUCCESS,
	ADD_CLASSROOM_FAILURE,
	GET_QUESTION_PAPER,
	GET_QUESTION_PAPER_SUCCESS,
	GET_QUESTION_PAPER_FAILURE,
	REPORT_QUESTION,
	REPORT_QUESTION_SUCCESS,
	REPORT_QUESTION_FAILURE,
	GET_NOT_STARTED_TESTS_FAILED,
	SHOW_INCOMPLETE_DETAILS_MODAL,
	CLEAR_PAST_USER_GIVING_TEST_DATA,
} from "./type";

// const appUrl = "http://95.216.174.93:8080/eddytech";
// const headers = {
// 	Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
// };

export const getQuestionsById = (testId) => async (dispatch) => {
	dispatch({
		type: GET_TEST_QUESTIONS,
	});
	try {
		//OLD ONE WHICH GIVES WITHOUT SECTION SEGREGATION
		//const res = await axios.get("/v1/testQuestion/test/" + payload);

		//NEW API URL WHICH GIVES DATA WITH SEGREGATION URL
		const res = await axios.get("/v1/testQuestion/test/" + testId + "/v1");
		dispatch({
			type: GET_TEST_QUESTIONS_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: GET_TEST_QUESTIONS_FAILED,
			payload: error,
		});
	}
};

//offset is starting from 0 as the package using it for infinite scroll is creating some issue.
export const getTestsByStatus =
	(payload, limit = 10) =>
	async (dispatch) => {
		if (payload === "IN PROGRESS") {
			dispatch({
				type: GET_INPROGRESS_TESTS,
			});
			try {
				const res = await axios.get(
					`/v1/test/status/${payload}?limit=${limit}&offset=0`
				);
				dispatch({
					type: GET_INPROGRESS_TESTS_SUCCESS,
					payload: res.data,
				});
			} catch (err) {
				const error = get(err, "response.data.message");
				const statusCode = err?.response?.data?.code;
				const errorMessage = err?.response?.data?.message;

				//404 is handled by getTotalTestsCountByUser Count API
				if (
					statusCode !== 404 &&
					errorMessage !== "Students Details are incomplete!!!"
				) {
					dispatch({
						type: GET_INPROGRESS_TESTS_FAILED,
						payload: error,
					});
				}
			}
		} else if (payload === "COMPLETED") {
			dispatch({
				type: GET_COMPLETED_TESTS,
			});
			try {
				const res = await axios.get(
					`/v1/test/status/${payload}?limit=${limit}&offset=0`
				);
				dispatch({
					type: GET_COMPLETED_TESTS_SUCCESS,
					payload: res.data,
				});
			} catch (err) {
				const error = get(err, "response.data.message");
				const statusCode = err?.response?.data?.code;
				const errorMessage = err?.response?.data?.message;

				//404 is handled by getTotalTestsCountByUser Count API
				if (
					statusCode !== 404 &&
					errorMessage !== "Students Details are incomplete!!!"
				) {
					dispatch({
						type: GET_COMPLETED_TESTS_FAILED,
						payload: error,
					});
				}
			}
		} else if (payload === "NOT STARTED") {
			dispatch({
				type: GET_NOT_STARTED_TESTS,
			});
			try {
				const res = await axios.get(
					`/v1/test/status/${payload}?limit=${limit}&offset=0`
				);
				dispatch({
					type: GET_NOT_STARTED_TESTS_SUCCESS,
					payload: res.data,
				});
			} catch (err) {
				const error = get(err, "response.data.message");
				const statusCode = err?.response?.data?.code;
				const errorMessage = err?.response?.data?.message;

				//404 is handled by getTotalTestsCountByUser Count API
				if (
					statusCode !== 404 &&
					errorMessage !== "Students Details are incomplete!!!"
				) {
					dispatch({
						type: GET_NOT_STARTED_TESTS_FAILED,
						payload: error,
					});
				}
			}
		}
	};

export const getTestResult = (payload) => async (dispatch) => {
	dispatch({
		type: GET_TEST_RESULT,
	});
	try {
		const res = await axios.get("/v1/testResult/testResultId/" + payload);
		dispatch({
			type: GET_TEST_RESULT_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: GET_TEST_RESULT_FAILED,
			payload: error,
		});
	}
};

export const getTestResultAnswerResponse = (payload) => async (dispatch) => {
	dispatch({
		type: GET_TEST_RESULT_ANS_RESPONSE,
	});
	try {
		const res = await axios.get(
			"/v1/testResultAnswer/response/testId/" + payload + "/v1"
		);
		dispatch({
			type: GET_TEST_RESULT_ANS_RESPONSE_SUCCESS,
			payload: res.data,
		});
		//console.log(`res.data`, res.data);
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: GET_TEST_RESULT_ANS_RESPONSE_FAILED,
			payload: error,
		});
	}
};

export const getQuestionsByCategory = (payload) => async (dispatch) => {
	dispatch({
		type: GET_QUESTIONS,
	});
	try {
		const res = await axios.get(
			"/v1/testQuestion/testQuestion/" + payload + "/category"
		);
		dispatch({
			type: GET_QUESTIONS_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: GET_QUESTIONS_FAILED,
			payload: error,
		});
	}
};

export const getQuestionAnalysis = (payload) => async (dispatch) => {
	dispatch({
		type: GET_QUESTION_ANALYSIS,
	});
	try {
		const res = await axios.get(
			"/v1/testResult/analysis/v1?testId=" +
				payload.testId +
				"&testResultId=" +
				payload.testResultId
		);
		dispatch({
			type: GET_QUESTION_ANALYSIS_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: GET_QUESTION_ANALYSIS_FAILED,
			payload: error,
		});
	}
};

export const getSubjects = (payload) => async (dispatch) => {
	dispatch({
		type: GET_SUBJECTS,
	});
	try {
		const res = await axios.post("/v1/meta/subject", payload);
		dispatch({
			type: GET_SUBJECTS_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: GET_SUBJECTS_FAILED,
			payload: error,
		});
	}
};

export const getClassrooms = () => async (dispatch) => {
	dispatch({
		type: GET_CLASSROOM,
	});
	try {
		const res = await axios.get(`/v1/meta/classroom`);

		dispatch({
			type: GET_CLASSROOM_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);

		dispatch({
			type: GET_CLASSROOM_FAILED,
			payload: error,
		});
	}
};

export const saveAnswerResponse = (payload) => async (dispatch) => {
	dispatch({
		type: SAVE_ANS_RESPONSE,
	});
	try {
		const res = axios.post("/v1/testResultAnswer/save", payload);
		dispatch({
			type: SAVE_ANS_RESPONSE_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: SAVE_ANS_RESPONSE_FAILED,
			payload: error,
		});
	}
};

export const saveTestAnswerForIndividualQus = (payload) => async (dispatch) => {
	dispatch({
		type: SAVE_ANS,
	});
	try {
		const res = await axios.post(`/v1/testResultAnswer/save/v1`, payload);
		dispatch({
			type: SAVE_ANS_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: SAVE_ANS_FAILED,
			payload: error,
		});
	}
};

export const submitTest = (payload) => async (dispatch) => {
	dispatch({
		type: SUBMIT_TEST,
	});
	try {
		const res = await axios.post(`/v1/testResult/save`, payload);
		dispatch({
			type: SUBMIT_TEST_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: SUBMIT_TEST_FAILED,
			payload: error,
		});
	}
};

export const updateTestStatus = (payload) => async (dispatch) => {
	dispatch({
		type: UPDATE_TEST_STATUS,
	});
	try {
		const res = await axios.post(`/v1/testResult/status`, payload);
		dispatch({
			type: UPDATE_TEST_STATUS_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: UPDATE_TEST_STATUS_FAILED,
			payload: error,
		});
	}
};

export const getTestList = (payload) => async (dispatch) => {
	//console.log("check");
	dispatch({
		type: GET_TEST_LIST,
	});
	try {
		const res = await axios.get(`/v1/test/list`, {
			params: { offSet: payload.offSet, limit: payload.limit },
		});
		//const res = await axios.get(`/v1/test/list?${payload}`);
		dispatch({
			type: GET_TEST_LIST_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: GET_TEST_LIST_FAILED,
			payload: error,
		});
	}
};

export const getTotalTestCount = () => async (dispatch) => {
	dispatch({
		type: GET_TOTAL_TEST_COUNT,
	});
	try {
		// const res = await axios.get(appUrl + `/v1/test/count`, {
		// 	headers: headers,
		// });
		const res = await axios.get(`/v1/test/count`);
		dispatch({
			type: GET_TOTAL_TEST_COUNT_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: GET_TOTAL_TEST_COUNT_FAILED,
			payload: error,
		});
	}
};

export const addNewTest = (payload) => async (dispatch) => {
	dispatch({
		type: ADD_NEW_TEST,
	});
	try {
		const res = await axios.post(`/v1/test/assign`, payload);
		dispatch({
			type: ADD_NEW_TEST_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: ADD_NEW_TEST_FAILED,
			payload: error,
		});
	}
};

export const deleteQuestionFromTest = (payload) => async (dispatch) => {
	dispatch({
		type: DELETE_QUESTION,
	});

	try {
		const res = await axios.delete(
			`/v1/testQuestion?id=${payload.id}&testId=${payload.testId}`
		);

		dispatch({
			type: DELETE_QUESTION_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: DELETE_QUESTION_FAILURE,
			payload: errors,
		});
	}
};

export const getQuestionMetaData = () => async (dispatch) => {
	dispatch({
		type: QUESTION_META_DATA,
	});

	try {
		const res = await axios.get("/v1/meta/question");

		dispatch({
			type: QUESTION_META_DATA_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: QUESTION_META_DATA_FAILURE,
			payload: error,
		});
	}
};

export const getSubjectMetaData = (payload) => async (dispatch) => {
	dispatch({
		type: SUBJECT_META_DATA,
	});

	try {
		const res = await axios.get(
			"/v1/meta/question/filter/subjectId?standardId=" + payload
		);

		dispatch({
			type: SUBJECT_META_DATA_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: SUBJECT_META_DATA_FAILURE,
			payload: error,
		});
	}
};

export const getChapterMetaData = (payload) => async (dispatch) => {
	dispatch({
		type: CHAPTER_META_DATA,
	});

	try {
		const res = await axios.get(
			`/v1/meta/question/filter/chapterId?standardId=${payload.standardId}&subjectId=${payload.subjectId}`
		);

		dispatch({
			type: CHAPTER_META_DATA_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: CHAPTER_META_DATA_FAILURE,
			payload: error,
		});
	}
};

export const getTopicMetaData = (payload) => async (dispatch) => {
	dispatch({
		type: TOPIC_META_DATA,
	});

	try {
		const res = await axios.get(
			`/v1/meta/question/filter/topicId?standardId=${payload.standardId}&subjectId=${payload.subjectId}&chapterId=${payload.chapterId}`
		);

		dispatch({
			type: TOPIC_META_DATA_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: TOPIC_META_DATA_FAILURE,
			payload: error,
		});
	}
};

export const getQuestionToSet = (query, payload) => async (dispatch) => {
	dispatch({
		type: GET_QUESTIONS_TO_SET,
	});

	try {
		const res = await axios.post(
			`/v1/question/filter?offset=${query.offset}&limit=${query.limit}`,
			payload
		);

		dispatch({
			type: GET_QUESTIONS_TO_SET_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: GET_QUESTIONS_TO_SET_FAILURE,
			payload: error,
		});
	}
};

export const enrollStudent = (payload) => async (dispatch) => {
	dispatch({
		type: ENROLL_STUDENT,
	});
	try {
		const res = await axios.post(`/v1/student/enroll`, payload);
		dispatch({
			type: ENROLL_STUDENT_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message"); //currently error coming in data.message
		dispatch({
			type: ENROLL_STUDENT_FAILURE,
			payload: error,
		});
	}
};

export const getQuestionsCount = (payload) => async (dispatch) => {
	dispatch({
		type: GET_QUESTION_COUNT,
	});

	try {
		const res = await axios.post(`/v1/question/filter/count`, payload);

		dispatch({
			type: GET_QUESTION_COUNT_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: GET_QUESTION_COUNT_FAILURE,
			payload: error,
		});
	}
};

export const sendSelectedQuestionsToBackend = (payload) => async (dispatch) => {
	dispatch({
		type: SEND_SELECTED_QUESTION,
	});

	try {
		// const res = await axios.post(`/v1/testQuestion/question`, payload); //old without section wise tabs
		const res = await axios.post(`/test/testQuestion`, payload);

		dispatch({
			type: SEND_SELECTED_QUESTION_SUCCESS,
			payload: res.data.data.message,
		});

		//console.log("payload Data", res);
	} catch (err) {
		const error = get(err, "response.data.message");

		dispatch({
			type: SEND_SELECTED_QUESTION_FAILURE,
			payload: error,
		});
	}
};

export const getTotalTestsCountByUser = (payload) => async (dispatch) => {
	dispatch({
		type: TOTAL_TEST_COUNT_BY_USER,
	});

	try {
		const res = await axios.get(`/v1/test/status/${payload}/count`);

		dispatch({
			type: TOTAL_TEST_COUNT_BY_USER_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		const statusCode = err?.response?.data?.code;
		const errorMessage = err?.response?.data?.message;

		if (
			statusCode === 404 &&
			errorMessage === "Students Details are incomplete!!!"
		) {
			dispatch({
				type: SHOW_INCOMPLETE_DETAILS_MODAL,
			});
		} else {
			dispatch({
				type: TOTAL_TEST_COUNT_BY_USER_FAILURE,
				payload: error,
			});
		}
	}
};

export const addClassRoomApi = (payload) => async (dispatch) => {
	dispatch({
		type: ADD_CLASSROOM,
	});

	try {
		const res = await axios.post("/v1/classroom", payload);

		dispatch({
			type: ADD_CLASSROOM_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response?.error?.message");
		dispatch({
			type: ADD_CLASSROOM_FAILURE,
			payload: error,
		});
	}
};

export const getQuestionPaper = (payload) => async (dispatch) => {
	dispatch({
		type: GET_QUESTION_PAPER,
	});
	try {
		const res = await axios.get(
			`/questionPaper/${payload.status}?testId=${payload.testId}`
		);
		dispatch({
			type: GET_QUESTION_PAPER_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		//console.log(`error`, error);
		dispatch({
			type: GET_QUESTION_PAPER_FAILURE,
			payload: error,
		});
	}
};

export const dispatchReportQuestion = (payload) => async (dispatch) => {
	dispatch({
		type: REPORT_QUESTION,
	});
	try {
		const res = await axios.post("/v1/testQuestion/report", payload);
		dispatch({
			type: REPORT_QUESTION_SUCCESS,
			payload: res.data.data,
		});
	} catch (err) {
		const error = get(err, "response.data.message");
		dispatch({
			type: REPORT_QUESTION_FAILURE,
			payload: error,
		});
	}
};

export const clearPastUserGivingTestData = () => (dispatch) => {
	dispatch({
		type: CLEAR_PAST_USER_GIVING_TEST_DATA,
	});
};
