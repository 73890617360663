const {
	RENAME_CLASSROOM,
	RENAME_CLASSROOM_SUCCESS,
	RENAME_CLASSROOM_FAILURE,
	DELETE_CLASSROOM,
	DELETE_CLASSROOM_SUCCESS,
	DELETE_CLASSROOM_FAILURE,
	ERASE_CLASSROOM_MESSAGE,
	EDIT_STUDENT,
	EDIT_STUDENT_SUCCESS,
	EDIT_STUDENT_FAILURE,
	DELETE_STUDENT,
	DELETE_STUDENT_SUCCESS,
	DELETE_STUDENT_FAILURE,
	STUDENT_DETAILS_CLASSROOM,
	STUDENT_DETAILS_CLASSROOM_SUCCESS,
	STUDENT_DETAILS_CLASSROOM_FAILURE,
	GET_CLASSROOM_STUDENTS,
	GET_CLASSROOM_STUDENTS_SUCCESS,
	GET_CLASSROOM_STUDENTS_FAILURE,
	GET_CLASSROOM_STUDENTS_COUNT,
	GET_CLASSROOM_STUDENTS_SUCCESS_COUNT,
	GET_CLASSROOM_STUDENTS_FAILURE_COUNT,
	EDIT_CLASSROOM_STUDENTS_DETAILS,
	EDIT_CLASSROOM_STUDENTS_DETAILS_SUCCESS,
	EDIT_CLASSROOM_STUDENTS_DETAILS_FAILURE,
	ERASE_EDIT_STUDENT_DETAILS_MESSAGE,
	ADD_STUDENTS_IN_BULK,
	ADD_STUDENTS_IN_BULK_SUCCESS,
	ADD_STUDENTS_IN_BULK_FAILURE,
	GET_SEARCHED_STUDENT,
	GET_SEARCHED_STUDENT_SUCCESS,
	GET_SEARCHED_STUDENT_FAILURE,
} = require("../actions/type");

const initialState = {
	loading: false,
	error: "",
	message: "",
	data: {},
	studentsData: [],
	classroomStudentsCount: 0,
	editStudentMessage: "",
};

const addQuestionReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case RENAME_CLASSROOM:
			return {
				...state,
				loading: true,
				message: "",
				error: "",
			};
		case RENAME_CLASSROOM_SUCCESS:
			return {
				...state,
				loading: false,
				message: payload,
			};
		case RENAME_CLASSROOM_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case DELETE_CLASSROOM:
			return {
				...state,
				loading: true,
				message: "",
				error: "",
			};
		case DELETE_CLASSROOM_SUCCESS:
			return {
				...state,
				loading: false,
				message: payload,
			};
		case DELETE_CLASSROOM_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case ERASE_CLASSROOM_MESSAGE:
			return {
				...state,
				message: "",
				error: "",
			};

		case EDIT_STUDENT:
			return {
				...state,
				loading: true,
				message: "",
				error: "",
			};
		case EDIT_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				message: payload,
			};
		case EDIT_STUDENT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case DELETE_STUDENT:
			return {
				...state,
				loading: true,
				message: "",
				error: "",
			};
		case DELETE_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				message: payload,
			};
		case DELETE_STUDENT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case STUDENT_DETAILS_CLASSROOM:
			return {
				...state,
				loading: true,
			};
		case STUDENT_DETAILS_CLASSROOM_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case STUDENT_DETAILS_CLASSROOM_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case GET_CLASSROOM_STUDENTS:
			return {
				...state,
				loading: true,
				studentsData: [],
				error: "",
			};
		case GET_CLASSROOM_STUDENTS_SUCCESS:
			return {
				...state,
				loading: false,
				studentsData: payload,
			};
		case GET_CLASSROOM_STUDENTS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case GET_CLASSROOM_STUDENTS_COUNT:
			return {
				...state,
				loading: true,
				classroomStudentsCount: 0,
			};
		case GET_CLASSROOM_STUDENTS_SUCCESS_COUNT:
			return {
				...state,
				loading: false,
				classroomStudentsCount: payload,
			};
		case GET_CLASSROOM_STUDENTS_FAILURE_COUNT:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};

		case EDIT_CLASSROOM_STUDENTS_DETAILS:
			return {
				...state,
				loading: true,
				error: "",
				editStudentMessage: "",
			};
		case EDIT_CLASSROOM_STUDENTS_DETAILS_SUCCESS:
			console.log("editStudentMessage", payload);
			return {
				...state,
				loading: false,
				editStudentMessage: payload,
			};
		case EDIT_CLASSROOM_STUDENTS_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case ERASE_EDIT_STUDENT_DETAILS_MESSAGE:
			return {
				...state,
				editStudentMessage: "",
			};

		case ADD_STUDENTS_IN_BULK:
			return {
				...state,
				loading: true,
			};
		case ADD_STUDENTS_IN_BULK_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case ADD_STUDENTS_IN_BULK_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		case GET_SEARCHED_STUDENT:
			return {
				...state,
				loading: true,
			};
		case GET_SEARCHED_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				studentsData: payload,
			};
		case GET_SEARCHED_STUDENT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "something went wrong, Please try again later",
			};
		default:
			return state;
	}
};

export default addQuestionReducer;
