const {
	GET_TEST_RESULT,
	GET_TEST_RESULT_SUCCESS,
	GET_TEST_RESULT_FAILED,
	GET_TEST_RESULT_ANS_RESPONSE,
	GET_TEST_RESULT_ANS_RESPONSE_SUCCESS,
	GET_TEST_RESULT_ANS_RESPONSE_FAILED,
	GET_TEST_QUESTIONS_FAILED,
	GET_TEST_QUESTIONS,
	GET_TEST_QUESTIONS_SUCCESS,
	GET_INPROGRESS_TESTS,
	GET_INPROGRESS_TESTS_SUCCESS,
	GET_INPROGRESS_TESTS_FAILED,
	GET_COMPLETED_TESTS,
	GET_COMPLETED_TESTS_SUCCESS,
	GET_COMPLETED_TESTS_FAILED,
	GET_NOT_STARTED_TESTS,
	GET_NOT_STARTED_TESTS_SUCCESS,
	GET_NOT_STARTED_TESTS_FAILED,
	GET_QUESTIONS,
	GET_QUESTIONS_SUCCESS,
	GET_QUESTIONS_FAILED,
	GET_QUESTION_ANALYSIS,
	GET_QUESTION_ANALYSIS_SUCCESS,
	SAVE_ANS_RESPONSE_FAILED,
	SAVE_ANS_RESPONSE,
	SAVE_ANS_RESPONSE_SUCCESS,
	SAVE_TEST_ANS_RESPONSE,
	SAVE_TEST_ANS_RESPONSE_SUCCESS,
	SAVE_TEST_ANS_RESPONSE_FAILED,
	SAVE_ANS,
	SAVE_ANS_SUCCESS,
	SAVE_ANS_FAILED,
	SUBMIT_TEST,
	SUBMIT_TEST_SUCCESS,
	SUBMIT_TEST_FAILED,
	UPDATE_TEST_STATUS,
	UPDATE_TEST_STATUS_SUCCESS,
	UPDATE_TEST_STATUS_FAILED,
	GET_QUESTION_ANALYSIS_FAILED,
	GET_TEST_LIST,
	GET_TEST_LIST_SUCCESS,
	GET_TEST_LIST_FAILED,
	GET_TOTAL_TEST_COUNT,
	GET_TOTAL_TEST_COUNT_SUCCESS,
	GET_TOTAL_TEST_COUNT_FAILED,
	GET_CLASSROOM,
	GET_CLASSROOM_SUCCESS,
	GET_CLASSROOM_FAILED,
	GET_SUBJECTS,
	GET_SUBJECTS_SUCCESS,
	ADD_NEW_TEST,
	ADD_NEW_TEST_SUCCESS,
	ADD_NEW_TEST_FAILED,
	DELETE_QUESTION,
	DELETE_QUESTION_SUCCESS,
	DELETE_QUESTION_FAILURE,
	QUESTION_META_DATA,
	QUESTION_META_DATA_SUCCESS,
	QUESTION_META_DATA_FAILURE,
	SUBJECT_META_DATA,
	SUBJECT_META_DATA_SUCCESS,
	SUBJECT_META_DATA_FAILURE,
	CHAPTER_META_DATA,
	CHAPTER_META_DATA_SUCCESS,
	CHAPTER_META_DATA_FAILURE,
	TOPIC_META_DATA,
	TOPIC_META_DATA_SUCCESS,
	TOPIC_META_DATA_FAILURE,
	GET_QUESTIONS_TO_SET,
	GET_QUESTIONS_TO_SET_SUCCESS,
	GET_QUESTIONS_TO_SET_FAILURE,
	GET_QUESTION_COUNT,
	GET_QUESTION_COUNT_SUCCESS,
	GET_QUESTION_COUNT_FAILURE,
	SEND_SELECTED_QUESTION,
	SEND_SELECTED_QUESTION_SUCCESS,
	SEND_SELECTED_QUESTION_FAILURE,
	ENROLL_STUDENT,
	ENROLL_STUDENT_FAILURE,
	ENROLL_STUDENT_SUCCESS,
	TOTAL_TEST_COUNT_BY_USER,
	TOTAL_TEST_COUNT_BY_USER_SUCCESS,
	TOTAL_TEST_COUNT_BY_USER_FAILURE,
	ADD_CLASSROOM,
	ADD_CLASSROOM_SUCCESS,
	ADD_CLASSROOM_FAILURE,
	GET_QUESTION_PAPER,
	GET_QUESTION_PAPER_SUCCESS,
	GET_QUESTION_PAPER_FAILURE,
	REPORT_QUESTION,
	REPORT_QUESTION_SUCCESS,
	REPORT_QUESTION_FAILURE,
	SHOW_INCOMPLETE_DETAILS_MODAL,
	CLEAR_PAST_USER_GIVING_TEST_DATA,
} = require("../actions/type");

const initialState = {
	loading: false,
	testLoading: false,
	error: false,
	success: false,
	data: {},
	result: {},
	resultAnsResponse: [],
	testList: {},
	classrooms: [],
	count: 0,
	testQuestionData: [],
	questionData: {},
	questionDataLoading: false,
	analysisData: [],
	analysisDataLoading: false,
	inProgressData: [],
	inProgressDataLoading: false,
	completedData: [],
	completedDataLoading: false,
	notStartedData: [],
	notStartedDataLoading: false,
	subjects: {},
	subjectLoading: false,
	testListLoading: false,
	questionMetaData: {},
	subjectMetaData: [],
	chapterMetaData: [],
	topicMetaData: [],
	questionsSetData: [],
	questionsSetloading: false,
	questionCount: 0,
	sendQuestionLoading: false,
	sendQuestionError: "",
	addNewTestLoading: false,
	enrolledStatus: "",
	enrolledFlag: false,
	classroomAddStatus: "",
	classroomAddStatusError: "",
	questionPaperData: {},
	questionPaperDataLoading: false,
	questionPaperDataError: "",
	deleteError: "",
	addQuestionsToTestError: "",
	reportQuestionPayload: "",
	reportQuestionError: "",
	deleteQuestionLoading: false,
	showIncompleteDetailsModal: false,
};

const testReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_TEST_QUESTIONS:
			return {
				...state,
				testLoading: true,
			};

		case GET_TEST_QUESTIONS_SUCCESS:
			return {
				...state,
				testLoading: false,
				testQuestionData: payload,
			};
		case GET_TEST_QUESTIONS_FAILED:
			return {
				...state,
				testLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_TEST_RESULT:
			return {
				...state,
				loading: true,
				result: {}, //emptying it sa that it doesnt contain old data
			};

		case GET_TEST_RESULT_SUCCESS:
			return {
				...state,
				loading: false,
				result: payload,
			};
		case GET_TEST_RESULT_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_TEST_RESULT_ANS_RESPONSE:
			return {
				...state,
				loading: true,
				resultAnsResponse: [], //deleting past data
			};

		case GET_TEST_RESULT_ANS_RESPONSE_SUCCESS:
			return {
				...state,
				loading: false,
				resultAnsResponse: payload,
			};
		case GET_TEST_RESULT_ANS_RESPONSE_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_INPROGRESS_TESTS:
			return {
				...state,
				inProgressDataLoading: true,
				error: "", //emptying from previous
				analysisData: [], //emptying it as old data is hampering with others
				result: {}, //emptying it sa that it doesnt contain old data
			};

		case GET_INPROGRESS_TESTS_SUCCESS:
			return {
				...state,
				inProgressDataLoading: false,
				inProgressData: payload,
			};
		case GET_INPROGRESS_TESTS_FAILED:
			return {
				...state,
				inProgressDataLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_NOT_STARTED_TESTS:
			return {
				...state,
				notStartedDataLoading: true,
				error: "", //emptying from previous
				analysisData: [], //emptying it as old data is hampering with others
				result: {}, //emptying it sa that it doesnt contain old data
			};

		case GET_NOT_STARTED_TESTS_SUCCESS:
			return {
				...state,
				notStartedDataLoading: false,
				notStartedData: payload,
			};
		case GET_NOT_STARTED_TESTS_FAILED:
			return {
				...state,
				notStartedDataLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_COMPLETED_TESTS:
			return {
				...state,
				completedDataLoading: true,
				error: "", //emptying from previous
				analysisData: [], //emptying it as old data is hampering with others
				result: {}, //emptying it sa that it doesnt contain old data
			};

		case GET_COMPLETED_TESTS_SUCCESS:
			return {
				...state,
				completedDataLoading: false,
				completedData: payload,
			};
		case GET_COMPLETED_TESTS_FAILED:
			return {
				...state,
				completedDataLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_QUESTIONS:
			return {
				...state,
				questionDataLoading: true,
			};

		case GET_QUESTIONS_SUCCESS:
			return {
				...state,
				questionDataLoading: false,
				questionData: payload,
			};
		case GET_QUESTIONS_FAILED:
			return {
				...state,
				questionDataLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_QUESTION_ANALYSIS:
			return {
				...state,
				analysisDataLoading: true,
				analysisData: [], //emptying it as old data is hampering with others
				error: "",
				reportQuestionPayload: "",
				reportQuestionError: "",
			};

		case GET_QUESTION_ANALYSIS_SUCCESS:
			return {
				...state,
				analysisDataLoading: false,
				analysisData: payload,
			};
		case GET_QUESTION_ANALYSIS_FAILED:
			return {
				...state,
				analysisDataLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_TEST_LIST:
			return {
				...state,
				testListLoading: true,
				enrolledFlag: false, //deleting past data after enrolled student api call
				enrolledStatus: "", //deleting past data after enrolled student api call
				classroomAddStatus: "", //removing old status
				classroomAddStatusError: "", //removing old error
				deleteError: "", //removing old error
				error: "", //removing old error
			};

		case GET_TEST_LIST_SUCCESS:
			return {
				...state,
				testListLoading: false,
				testList: payload,
			};
		case GET_TEST_LIST_FAILED:
			return {
				...state,
				testListLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case GET_TOTAL_TEST_COUNT:
			//here subject, chapter, topic, questionSet and questionCOunt is made 0 as
			//due to the bug in page add questions to test where prev data was showing
			//even they were for diffrent testIds and no filters were applied.
			return {
				...state,
				loading: true,
				subjectMetaData: [],
				chapterMetaData: [],
				topicMetaData: [],
				questionsSetData: [],
				questionCount: 0,
				addQuestionsToTestError: "",
				success: false,
			};

		case GET_TOTAL_TEST_COUNT_SUCCESS:
			return {
				...state,
				laoding: false,
				count: payload,
			};

		case GET_TOTAL_TEST_COUNT_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_CLASSROOM:
			return {
				...state,
				loading: true,
			};

		case GET_CLASSROOM_SUCCESS:
			return {
				...state,
				loading: false,
				classrooms: payload,
			};

		case GET_CLASSROOM_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case SAVE_ANS_RESPONSE:
			return {
				...state,
				loading: true,
			};

		case SAVE_ANS_RESPONSE_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case SAVE_ANS_RESPONSE_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case SAVE_TEST_ANS_RESPONSE:
			return {
				...state,
				loading: true,
			};

		case SAVE_TEST_ANS_RESPONSE_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case SAVE_TEST_ANS_RESPONSE_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case SAVE_ANS:
			return {
				...state,
				loading: true,
			};

		case SAVE_ANS_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case SAVE_ANS_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case SUBMIT_TEST:
			return {
				...state,
				loading: true,
			};

		case SUBMIT_TEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case SUBMIT_TEST_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case UPDATE_TEST_STATUS:
			return {
				...state,
				loading: true,
			};

		case UPDATE_TEST_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case UPDATE_TEST_STATUS_FAILED:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_SUBJECTS:
			return {
				...state,
				subjectLoading: true,
			};
		case GET_SUBJECTS_SUCCESS:
			return {
				...state,
				subjects: payload,
				subjectLoading: false,
			};

		case ADD_NEW_TEST:
			return {
				...state,
				addNewTestLoading: true,
			};

		case ADD_NEW_TEST_SUCCESS:
			return {
				...state,
				addNewTestLoading: false,
				data: payload,
			};

		case ADD_NEW_TEST_FAILED:
			return {
				...state,
				addNewTestLoading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case DELETE_QUESTION:
			return {
				...state,
				deleteQuestionLoading: true,
			};

		case DELETE_QUESTION_SUCCESS:
			return {
				...state,
				deleteQuestionLoading: false,
				data: payload,
			};

		case DELETE_QUESTION_FAILURE:
			return {
				...state,
				deleteQuestionLoading: false,
				deleteError: payload || "Something went wrong, Please try again later",
			};

		case QUESTION_META_DATA:
			return {
				...state,
				loading: true,
			};

		case QUESTION_META_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				questionMetaData: payload,
			};

		case QUESTION_META_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case SUBJECT_META_DATA:
			return {
				...state,
				loading: true,
			};

		case SUBJECT_META_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				subjectMetaData: payload,
			};

		case SUBJECT_META_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case CHAPTER_META_DATA:
			return {
				...state,
				loading: true,
			};

		case CHAPTER_META_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				chapterMetaData: payload,
			};

		case CHAPTER_META_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case TOPIC_META_DATA:
			return {
				...state,
				loading: true,
			};

		case TOPIC_META_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				topicMetaData: payload,
			};

		case TOPIC_META_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case GET_QUESTIONS_TO_SET:
			return {
				...state,
				questionsSetloading: true,
				questionsSetData: [], //erasing past data
				error: "", //erasing past data
			};

		case GET_QUESTIONS_TO_SET_SUCCESS:
			return {
				...state,
				questionsSetloading: false,
				questionsSetData: payload,
			};

		case GET_QUESTIONS_TO_SET_FAILURE:
			return {
				...state,
				questionsSetloading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case GET_QUESTION_COUNT:
			return {
				...state,
				loading: true,
				addQuestionsToTestError: "",
				success: false,
			};

		case GET_QUESTION_COUNT_SUCCESS:
			return {
				...state,
				loading: false,
				questionCount: payload,
			};

		case GET_QUESTION_COUNT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case SEND_SELECTED_QUESTION:
			return {
				...state,
				sendQuestionLoading: true,
				addQuestionsToTestError: "", //erasing past data
				success: false, //erasing past data
			};

		case SEND_SELECTED_QUESTION_SUCCESS:
			return {
				...state,
				sendQuestionLoading: false,
				success: payload,
			};

		case SEND_SELECTED_QUESTION_FAILURE:
			return {
				...state,
				sendQuestionLoading: false,
				addQuestionsToTestError:
					payload || "Something went wrong, Please try again later",
			};
		case ENROLL_STUDENT:
			return {
				...state,
				loading: true,
				enrolledFlag: false,
				enrolledStatus: "",
			};
		case ENROLL_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				enrolledFlag: true,
				enrolledStatus: "Student successfully enrolled.",
			};

		case ENROLL_STUDENT_FAILURE:
			return {
				...state,
				loading: false,
				enrolledFlag: false,
				enrolledStatus: payload || "Failed to enroll. Please try again.",
			};

		case TOTAL_TEST_COUNT_BY_USER:
			return {
				...state,
				loading: true,
				//as if it becomes true then user did his to complete the details then incomplete modal
				//should not be shown again
				showIncompleteDetailsModal: false,
			};

		case TOTAL_TEST_COUNT_BY_USER_SUCCESS:
			return {
				...state,
				loading: false,
				totalTestCountByUser: payload,
			};

		case TOTAL_TEST_COUNT_BY_USER_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case ADD_CLASSROOM:
			return {
				...state,
				loading: true,
				classroomAddStatus: "", //removing old status
				classroomAddStatusError: "", //removing old error
			};
		case ADD_CLASSROOM_SUCCESS:
			return {
				...state,
				loading: false,
				classroomAddStatus: payload,
			};
		case ADD_CLASSROOM_FAILURE:
			return {
				...StaticRange,
				loading: false,
				classroomAddStatusError:
					payload || "Something went wrong, Please try again later",
			};
		case GET_QUESTION_PAPER:
			return {
				...state,
				questionPaperDataLoading: true,
				questionPaperData: {},
				questionPaperDataError: "",
			};
		case GET_QUESTION_PAPER_SUCCESS:
			return {
				...state,
				questionPaperDataLoading: false,
				questionPaperData: payload,
			};
		case GET_QUESTION_PAPER_FAILURE:
			return {
				...state,
				questionPaperDataLoading: false,
				questionPaperDataError:
					payload || "something went wrong, Please try again later",
			};
		case REPORT_QUESTION:
			return {
				...state,
				loading: true,
				reportQuestionPayload: "",
				reportQuestionError: "",
			};
		case REPORT_QUESTION_SUCCESS:
			//console.log("reportQuestionPayload", payload);
			return {
				...state,
				loading: false,
				reportQuestionPayload: payload,
			};
		case REPORT_QUESTION_FAILURE:
			//console.log("payload", payload);
			return {
				...state,
				loading: false,
				reportQuestionError:
					payload || "Something went wrong, Please try again later",
			};

		case SHOW_INCOMPLETE_DETAILS_MODAL:
			return {
				...state,
				loading: false,
				notStartedDataLoading: false,
				completedDataLoading: false,
				inProgressDataLoading: false,
				showIncompleteDetailsModal: true,
			};
		case CLEAR_PAST_USER_GIVING_TEST_DATA:
			return {
				...state,
				testQuestionData: [],
				resultAnsResponse: [],
				error: "",
			};
		default:
			return state;
	}
};

export default testReducer;
