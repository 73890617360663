import {
	GET_RESULT_FOR_EACH_TEST,
	GET_RESULT_FOR_EACH_TEST_SUCCESS,
	GET_RESULT_FOR_EACH_TEST_FAILURE,
	UPDATE_TEST_DATA,
	UPDATE_TEST_DATA_SUCCESS,
	UPDATE_TEST_DATA_FAILURE,
	CHANGE_PAGE_FOR_MANAGE_TEST,
	CHANGE_ROWS_PER_PAGE_FOR_MANAGE_TEST,
	GET_PROFILE_ADMIN,
	GET_PROFILE_ADMIN_SUCCESS,
	GET_PROFILE_ADMIN_FAILURE,
	EDIT_PROFILE_ADMIN,
	EDIT_PROFILE_ADMIN_SUCCESS,
	EDIT_PROFILE_ADMIN_FAILURE,
	ERASE_MSG_AND_ERROR_PROFILE_EDIT_ADMIN,
	ERASE_MSG_AND_ERROR_EDIT_TEST,
	GET_TEST_FROM_SEARCH_TEXT_ADMIN,
	GET_TEST_FROM_SEARCH_TEXT_SUCCESS_ADMIN,
	GET_TEST_FROM_SEARCH_TEXT_FAILURE_ADMIN,
	ERASE_SEARCH_ERROR_ADMIN,
	GET_SEARCH_TEST_COUNT_ADMIN,
	GET_SEARCH_TEST_COUNT_ADMIN_SUCCESS,
	GET_SEARCH_TEST_COUNT_ADMIN_FAILURE,
	RESET_TEST_MODAL,
	RESET_TEST_MODAL_SUCCESS,
	RESET_TEST_MODAL_FAILURE,
	ERASE_RESET_TEST_MESSAGE_AND_ERROR,
	GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT,
	GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_SUCCESS,
	GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_FAILURE,
	GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT,
	GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_SUCCESS,
	GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_FAILURE,
} from "../actions/type";

const initialState = {
	loading: false,
	error: "",
	data: {},
	message: "",
	pageNumber: 0,
	rowsPerPage: 10,
	resultForAllStudentsError: "",
	editProfileMessage: "",
	editProfileError: "",
	searchTestCount: 0,
	searchTestData: [],
	searchError: "",
	resetTestError: "",
	resetTestMessage: "",
	allResultOfIndividualStudent: [],
	allResultOfIndividualStudentCount: {},
	countAllResultError: "",
	countAllResultLoading: false,
};

const adminMiscReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_RESULT_FOR_EACH_TEST:
			return {
				...state,
				loading: true,
				resultForAllStudentsError: "",
				data: {},
			};

		case GET_RESULT_FOR_EACH_TEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};

		case GET_RESULT_FOR_EACH_TEST_FAILURE:
			return {
				...initialState,
				loading: false,
				resultForAllStudentsError:
					payload || "Something went wrong, Please try again later",
			};

		case UPDATE_TEST_DATA:
			return {
				...state,
				loading: false,
				message: "",
				error: "",
			};
		case UPDATE_TEST_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				message: payload,
			};
		case UPDATE_TEST_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};

		case CHANGE_PAGE_FOR_MANAGE_TEST:
			return {
				...state,
				pageNumber: payload,
			};
		case CHANGE_ROWS_PER_PAGE_FOR_MANAGE_TEST:
			return {
				...state,
				rowsPerPage: payload,
				pageNumber: 0,
			};
		case GET_PROFILE_ADMIN:
			return {
				...state,
				loading: true,
				data: {},
				error: "",
			};
		case GET_PROFILE_ADMIN_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			};
		case GET_PROFILE_ADMIN_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case EDIT_PROFILE_ADMIN:
			return {
				...state,
				loading: true,
				editProfileMessage: "",
				editProfileError: "",
			};
		case EDIT_PROFILE_ADMIN_SUCCESS:
			return {
				...state,
				loading: false,
				editProfileMessage: payload,
			};
		case EDIT_PROFILE_ADMIN_FAILURE:
			return {
				...state,
				loading: false,
				editProfileError:
					payload || "Something went wrong, Please try again later",
			};

		case ERASE_MSG_AND_ERROR_PROFILE_EDIT_ADMIN:
			return {
				...state,
				editProfileMessage: "",
				editProfileError: "",
			};

		case ERASE_MSG_AND_ERROR_EDIT_TEST:
			return {
				...state,
				message: "",
				error: "",
			};

		case GET_TEST_FROM_SEARCH_TEXT_ADMIN:
			return {
				...state,
				loading: true,
				searchError: "",
				searchTestData: [],
			};

		case GET_TEST_FROM_SEARCH_TEXT_SUCCESS_ADMIN:
			return {
				...state,
				loading: false,
				searchTestData: payload,
			};

		case GET_TEST_FROM_SEARCH_TEXT_FAILURE_ADMIN:
			return {
				...state,
				loading: false,
				searchError: payload || "Something went wrong, Please try again later",
			};

		case ERASE_SEARCH_ERROR_ADMIN:
			return {
				...state,
				searchError: "",
			};
		case GET_SEARCH_TEST_COUNT_ADMIN:
			return {
				...state,
				loading: true,
				searchTestCount: 0,
				searchError: "",
			};
		case GET_SEARCH_TEST_COUNT_ADMIN_SUCCESS:
			return {
				...state,
				loading: false,
				searchTestCount: payload,
			};

		case GET_SEARCH_TEST_COUNT_ADMIN_FAILURE:
			return {
				...state,
				loading: false,
				searchError: payload || "Something went wrong, Please try again later",
			};
		case RESET_TEST_MODAL:
			return {
				...state,
				loading: true,
			};
		case RESET_TEST_MODAL_SUCCESS:
			return {
				...state,
				loading: false,
				resetTestMessage: payload || "Test has been reset Successfully",
			};
		case RESET_TEST_MODAL_FAILURE:
			return {
				...state,
				loading: false,
				resetTestError:
					payload || "Something went wrong, Please try again later",
			};
		case ERASE_RESET_TEST_MESSAGE_AND_ERROR:
			return {
				...state,
				resetTestError: "",
				resetTestMessage: "",
			};

		case GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT:
			return {
				...state,
				loading: true,
				error: "",
				allResultOfIndividualStudent: [],
			};
		case GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_SUCCESS:
			return {
				...state,
				loading: false,
				allResultOfIndividualStudent: payload,
			};
		case GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT:
			return {
				...state,
				countAllResult: true,
				countError: "",
				allResultOfIndividualStudentCount: {},
			};
		case GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_SUCCESS:
			return {
				...state,
				countAllResult: false,
				allResultOfIndividualStudentCount: payload,
			};
		case GET_ALL_RESULT_OF_INDIVIDUAL_STUDENT_COUNT_FAILURE:
			return {
				...state,
				countAllResult: false,
				countAllError:
					payload || "Something went wrong, Please try again later",
			};

		default:
			return state;
	}
};

export default adminMiscReducer;
